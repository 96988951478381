import { Contact } from '@root/interfaces/contact.interface';
import { Customer } from '@root/interfaces/enterprise.interface';
import { Provider } from '@customer/interfaces/provider.interface';

export interface Approval {
  id: number;
  sponsor: { name: string, legalForm: string, identification_number: string, email: string }
  provider: Provider;
  mission: { label: string, description: string };
  workfield: { label: string, description: string };
  status?: ApprovalStatusEnum;
  sponsorContact?: Contact;
  providerContact?: Contact;
  businessAmount?: string;
  emissionDate?: Date;
}

export interface ApprovalProvider {
  id: string;
  sponsor: { name: string, legalForm: string, identification_number: string, email: string }
  customer: Customer;
  mission: { label: string, description: string };
  workfield: { label: string, description: string };
  status?: ApprovalStatusEnum;
  sponsorContact?: Contact;
  customerContact?: Contact;
  businessAmount?: string;
  emissionDate?: Date;
}

export interface NewApproval {
  id?: string;
  mission_id?: string;
  provider_id?: string;
  customer_id?: string;
  created_by?: string;
  sponsor_id?: string;
  tacit_delay?: number;
  status?: string;
  approved_by?: string;
  approved_at?: Date;
  refused_by?: string;
  refused_at?: Date;
  valid_from?: Date;
  valid_to?: Date;
  yousign_signature_request_id?: string;
  sponsor_email?: string;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export enum ApprovalStatusEnum {
  ACCEPTED = 'accepted',
  DRAFT = 'draft',
  READY = 'ready',
  REFUSED = 'refused',
  WAITING_CUSTOMER_VALIDATION = 'waitingCustomerValidation',
  WAITING_PROVIDER_VALIDATION = 'waitingProviderValidation',
  WAITING_FOR_CUSTOMER = 'waitingForCustomer',
  WAITING_FOR_PROVIDER = 'waitingForProvider',
  WAITING_FOR_SPONSOR = 'waitingForSponsor',
}

export interface ApprovalFilter {
  identity?: string;
  contact?: string;
  keyword?: string;
  marketType?: string;
  status?: string;
}

export enum ApprovalUserTypeEnum {
  CUSTOMER = 'customer',
  PROVIDER = 'provider',
}

export interface ApprovalVariable {
  id?: string;
  approval_id?: string;
  variable?: string;
  value?: string;
  user_type?: ApprovalUserTypeEnum;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

export interface ApprovalDetails {
  id: string;
  document_id?: string;
  mime_type?: string;
  mission_description?: string;
  mission_label?: string;
  provider_id?: string;
  provider_identification_number?: string;
  provider_legal_form?: string;
  provider_name?: string;
  sponsor_identification_number?: string;
  sponsor_legal_form?: string;
  sponsor_name?: string;
  sponsor_email?: string;
  status?: string;
  workfield_description?: string;
  workfield_label?: string;
  type_id?: string;
  file_id?: string;
  yousign_signature_request_id?: string;
  customer_user?: string;
  customer_email?: string;
}

export interface PropertySignature {
  pageNumberSignature?: number;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  id: number;
  signatureName: string;
}

export interface ApprovalSignature {
  id?: string;
  approval_id?: string;
  signature_link?: string;
  signer_id?: string;
  enterprise_id?: string;
  user_id?: string;
  user_type?: ApprovalUserTypeEnum;
  has_signed?: boolean;
  signed_at?: Date;
  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}
