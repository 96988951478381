import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AWLongCard from '@root/components/Cards/AWLongCard/AWLongCard';
import icon from '@root/assets/medal-solid.svg';
import { Approval, ApprovalStatusEnum } from '@root/interfaces/approval.interface';
import { formatDate } from '@root/helpers/utils';
import { AWColors } from '@root/interfaces/utils.interface';
import { Contact } from '@root/interfaces/contact.interface';

interface Props {
  approval: Approval,
  onClick: () => void
}

interface Signature {
  status: string;
  borderColor: string;
}

const handleDisplayOfContact = (contact?: Contact) => {
  if (contact) {
    const { first_name, last_name } = contact;
    if (first_name && last_name) {
      return `${first_name} ${last_name.slice(0, 1)}.`;
    }
    return first_name || last_name || '-';
  }
  return '-';
};

const CardApproval = ({ approval, onClick }: Props) => {
  const { t } = useTranslation();

  const [signature, setSignature] = useState<Signature>({ status: '', borderColor: '' });

  const signatureStatus = {
    allSignaturesMissing: t('CardApproval.allSignaturesMissing', 'aucune signature'),
    providerSignatureMissing: t(
      'CardApproval.providerSignatureMissing',
      'en attente de signature ST',
    ),
    customerSignatureMissing: t(
      'CardApproval.customerSignatureMissing',
      'en attente de signature client',
    ),
    sponsorSignatureMissing: t(
      'CardApproval.sponsorSignatureMissing',
      'en attente de signature MOA',
    ),
    signed: t('CardApproval.signed', 'signée'),
  };

  const setSignatureProperties = () => {
    if (
      approval.status === ApprovalStatusEnum.DRAFT
      || approval.status === ApprovalStatusEnum.READY
      || approval.status === ApprovalStatusEnum.WAITING_PROVIDER_VALIDATION
      || approval.status === ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION
    ) {
      setSignature({
        status: signatureStatus.allSignaturesMissing,
        borderColor: AWColors.Tomato,
      });
    }
    if (approval.status === ApprovalStatusEnum.WAITING_FOR_PROVIDER) {
      setSignature({
        status: signatureStatus.providerSignatureMissing,
        borderColor: AWColors.Yellow,
      });
    }
    if (approval.status === ApprovalStatusEnum.WAITING_FOR_CUSTOMER) {
      setSignature({
        status: signatureStatus.customerSignatureMissing,
        borderColor: AWColors.Yellow,
      });
    }
    if (approval.status === ApprovalStatusEnum.WAITING_FOR_SPONSOR) {
      setSignature({
        status: signatureStatus.sponsorSignatureMissing,
        borderColor: AWColors.Yellow,
      });
    }
    if (approval.status === ApprovalStatusEnum.ACCEPTED) {
      setSignature({ status: signatureStatus.signed, borderColor: AWColors.Green });
    }
  };

  useEffect(() => {
    setSignatureProperties();
  }, []);

  return (
    <AWLongCard
      icon={icon}
      onClick={onClick}
      borderColor={signature.borderColor}
      className="mb-3 text-black card-approval"
    >
      <Row>
        <Col className="d-flex flex-column justify-content-evenly card-approval__info" xs={5}>
          <Row>
            <Col className="d-flex flex-column justify-content-evenly" xs={5}>
              <Row>
                <span>
                  {t('Approvals.sponsor', 'MOA')}
                  {' '}
                  :
                </span>
              </Row>
              <Row>
                <span>
                  {t('Approvals.provider', 'ST')}
                  {' '}
                  :
                </span>
              </Row>
              <Row>
                <span>
                  {t('Approvals.workfield', 'Chantier')}
                  {' '}
                  :
                </span>
              </Row>
            </Col>
            <Col className="d-flex flex-column justify-content-evenly card-approval__info card-approval__work">
              <Row>
                <span className="card-approval__work__enterprises card-approval__text-ellipsis">
                  {/* {`${approval.sponsor.legalForm
                    ? approval.sponsor.legalForm
                    : ''} ${approval.sponsor.name}`} */}
                  {approval.sponsor.email ? approval.sponsor.email : '-'}
                </span>
              </Row>
              <Row>
                <span className="card-approval__work__enterprises card-approval__text-ellipsis">
                  {`${approval.provider.legalForm ? approval.provider.legalForm : ''} ${approval.provider.name}`}
                </span>
              </Row>
              <Row>
                <span className="card-approval__text-ellipsis">{approval.workfield.label}</span>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="d-flex flex-column justify-content-evenly card-approval__info" xs={3}>
          <Row>
            <span className="card-approval__text-ellipsis">
              {`${t('Approvals.contact', 'Contact')} : ${handleDisplayOfContact(approval.sponsorContact)}`}
            </span>
          </Row>
          <Row>
            <span className="card-approval__text-ellipsis">
              {`${t('Approvals.contact', 'Contact')} : ${handleDisplayOfContact(approval.providerContact)}`}
            </span>
          </Row>
          <Row>
            <span className="card-approval__text-ellipsis">
              {`${t('Approvals.mission', 'Mission')} : ${approval.mission.label}`}
            </span>
          </Row>
        </Col>
        <Col className="d-flex flex-column justify-content-around card-approval__info" xs={4}>
          <Row>
            <span>
              {`${t('Approvals.amount', "Montant d'affaires")} : ${approval.businessAmount} €`}
            </span>
          </Row>
          <Row>
            <span>
              {`${t('Approvals.emissionDate', "Date d'émission")} : ${formatDate(
                approval.emissionDate,
              )}`}
            </span>
          </Row>
          <Row>
            <span>{`${t('Approvals.status', 'Statut')} : ${signature.status}`}</span>
          </Row>
        </Col>
      </Row>
    </AWLongCard>
  );
};

CardApproval.defaultProps = {};

export default CardApproval;
