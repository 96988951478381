import { cloneDeep, map } from 'lodash';

import { getUser } from '@root/services/user.service';
import { User } from '@root/interfaces/user.interface';
import { AWColors, MergeOf } from '@root/interfaces/utils.interface';
import { randString, capitalize } from '@root/helpers/utils';

import pending from '@root/assets/pending.svg';
import usersCheck from '@root/assets/users-check.svg';
import valid from '@root/assets/valid.svg';
import invalid from '@root/assets/invalid.svg';

import i18n from '@root/locales/i18n';
import {
  Connection,
  ConnectionConfig,
  ConnectionFilter,
  ConnectionStatus,
  ConnectionStep,
  ConnectionStepStatus,
  ConnectionTask,
  ConnectionTaskContent,
  ConnectionTaskTypes,
  ConnectionUser,
  ConnectionUserType,
  InvitationRes,
  InvitationResError,
  TooltipMessage,
} from '@root/interfaces/connection.interface';
import {
  getConnectionConfigs,
  getEnterpriseConnections,
} from '@customer/services/connection.service';
import { ApiResponse } from '@root/helpers/response-handler';
import { Apps } from '@root/hooks/useCurrentApp';
import { CallbackSafeFetch } from '@root/hooks/useSafeFetch';
import { HookApiConfig } from '@root/interfaces/api-response.interface';
import { getUsersConfig } from '@root/api-configs/user.api.config';
import { getSurveyConfig } from '@root/api-configs/survey.api.config';
import { getFieldConfig } from '@root/api-configs/enterprise.api.config';
import { getDocumentTypeConfig } from '@root/api-configs/compliance.api.config';

const TASK_ADDWORKING_VALIDATION: ConnectionTaskContent[] = [
  ConnectionTaskTypes.DocumentType,
  ConnectionTaskTypes.Compliance,
  ConnectionTaskTypes.LegalCompliance,
  ConnectionTaskTypes.BusinessCompliance,
];

enum NewConnectionError {
  PartnershipAlreadyPartner = 'PARTNERSHIP_ALREADY_EXIST',
  ConnectionAlreadyExist = 'CONNECTION_ALREADY_EXIST',
}

interface TaskUpdateFunctionReturn {
  updatedBy?: string,
  updatedAt?: string,
  disabled: boolean,
}

interface TaskStatusFunctionReturn {
  icon: string,
  color: `${AWColors}`,
  status: string
}

interface TaskTitleFunctionReturn {
  title: string;
}

i18n.setDefaultNamespace(capitalize(Apps.Customer));

export const mapConnectionTasks = (
  connection: Connection,
  callback: (task: ConnectionTask) => ConnectionTask,
): Connection => ({
  ...connection,
  steps: connection.steps?.map((step) => ({
    ...step,
    tasks: step.tasks?.map(callback) || [],
  })) || [],
});

export const asyncMapConnectionTasks = async (
  connection: Connection,
  callback: (task: ConnectionTask, step: ConnectionStep) => Promise<ConnectionTask>,
) => {
  const steps = await Promise.all(
    connection.steps?.map(async (step) => {
      const tasks = await Promise.all(
        step.tasks.map((t) => callback(t, step)),
      );
      return {
        ...step,
        tasks,
      };
    }) || [],
  );
  return {
    ...connection,
    steps,
  };
};

export const buildTaskTitle = async (
  callbackApi: CallbackSafeFetch,
  task: ConnectionTask,
): Promise<TaskTitleFunctionReturn> => {
  if (task.content === ConnectionTaskTypes.Fields) {
    return { title: i18n.t('Connection.enterpriseInfo', "Informations de l'entreprise") };
  }
  if (task.content === ConnectionTaskTypes.LegalCompliance) {
    return { title: i18n.t('Connection.legalCompliance', 'Conformité légale') };
  }
  if (task.content === ConnectionTaskTypes.BusinessCompliance) {
    return { title: i18n.t('Connection.businessCompliance', 'Conformité métier') };
  }
  if (task.content === ConnectionTaskTypes.Compliance) {
    return { title: i18n.t('Connection.compliance', 'Conformité') };
  }
  if (task.content === ConnectionTaskTypes.Activity) {
    return { title: i18n.t('Connection.activities', 'Activités') };
  }
  if (task.content === ConnectionTaskTypes.Contact) {
    return { title: i18n.t('Connection.contact', 'Contact') };
  }
  if (task.content === ConnectionTaskTypes.Form && task.customer_data) {
    const surveyRes = await callbackApi(getSurveyConfig, { surveyId: task.customer_data });
    if (surveyRes.success) {
      return { title: `${i18n.t('Connection.survey', 'Questionnaire')} : ${surveyRes.data.label}` };
    }
  }
  if (task.content === ConnectionTaskTypes.CustomField && task.customer_data) {
    const fieldRes = await callbackApi(getFieldConfig, { fieldId: task.customer_data });
    if (fieldRes.success && fieldRes.data) {
      return { title: fieldRes.data.title };
    }
  }
  if (task.content === ConnectionTaskTypes.DocumentType && task.customer_data) {
    const documentTypeRes = await callbackApi(
      getDocumentTypeConfig,
      { documentTypeId: task.customer_data },
      { fields: 'display_name' },
    );
    if (documentTypeRes.success) {
      const name = documentTypeRes.data?.display_name || '';
      const activeLanguage = i18n.resolvedLanguage;
      try {
        return { title: JSON.parse(name)[activeLanguage || 'fr'] || JSON.parse(name).fr };
      } catch (e) {
        if ((<Error>e).name === 'SyntaxError') {
          return { title: name };
        }
        throw e;
      }
    }
  }
  return { title: task.content || '' };
};

const hasValidators = <T>(elements: T[], property: string, matcher: string) => !!(
  elements?.length && elements.some((item: T) => item[property] === matcher)
);

const isUserValidator = (
  elements: MergeOf<User, ConnectionUser>[],
  userId: string,
  { key, matcher }: {
    key: string,
    matcher: string,
  },
) => !!(
  elements?.length && elements.some((item) => (
    item[key] === matcher && item.user_id === userId
  ))
);

const buildTaskUpdates = async (task: ConnectionTask): Promise<TaskUpdateFunctionReturn> => {
  const content: TaskUpdateFunctionReturn = { disabled: false };
  const updates = [task.done_at, task.validated_at, task.rejected_at];
  if (task?.done_by) {
    const userRes = await getUser(task.done_by);
    if (userRes && userRes.success) {
      content.updatedBy = `${userRes.data.firstname} ${userRes.data.lastname}`;
      content.disabled = true;
    }
  }
  const lastUpdate = Math.max(...updates.map((update) => (
    update ? new Date(update).getTime() : 0)));
  if (lastUpdate) content.updatedAt = new Date(lastUpdate).toString();
  return content;
};

const buildExtraStepValidation = async (
  step: ConnectionStep,
  connection: Connection,
  userId: string,
): Promise<ConnectionStep> => {
  const isStepAllowed = step.checkers && (
    !hasValidators(step.checkers, 'step_id', step.config_step_id)
    || isUserValidator(step.checkers, userId, { key: 'step_id', matcher: step.config_step_id })
  );

  // Get user from validated_by
  let validatorUser: User = {};
  if (step?.validated_by) {
    const userRes = await getUser(step.validated_by);
    if (userRes && userRes.success) {
      validatorUser = userRes?.data || {};
    }
  }

  // Create extra task (step validation done or not)
  let checkTask: ConnectionTask = {
    id: randString(),
    content: ConnectionTaskTypes.Check,
    updatedAt: step.validated_at,
    validated_at: step.validated_at,
    validator: validatorUser,
  };

  const allTasksValidated = step.tasks.every((t) => !!(t.validated_at));

  const previousSteps = connection.steps?.filter((s) => s.step < step.step) || [];
  const previousStepsOk = previousSteps.every((s) => (
    s.status === ConnectionStepStatus.Validated || !s.visible
  ));

  const isStepDisabled = (
    step.disabled
    || !isStepAllowed
    || !allTasksValidated
    || (step.step > 1 && !previousStepsOk)
  );

  if (step.validated_at) {
    checkTask = {
      ...checkTask,
      title: i18n.t('Connection.stepVerify', "Validation de l'étape"),
      icon: valid,
      color: AWColors.Green,
      status: i18n.t('Status.validated'),
      disabled: true,
    };
  } else {
    let tooltipMessage = TooltipMessage.ConditionNotFulfilled;
    if (!allTasksValidated && step.visible) {
      tooltipMessage = TooltipMessage.MissingTasks;
    } else if (!isStepAllowed) {
      tooltipMessage = TooltipMessage.NotAuthorized;
    } else if (!step.visible) {
      tooltipMessage = TooltipMessage.StepInactive;
    } else if (step.step > 1 && !previousStepsOk) {
      tooltipMessage = TooltipMessage.PreviousStepNotValidated;
    }
    checkTask = {
      ...checkTask,
      title: i18n.t('Connection.stepVerify', "Validation de l'étape"),
      icon: usersCheck,
      color: AWColors.Orange,
      status: i18n.t('Connection.pendingValidation', 'En attente de validation'),
      disabled: isStepDisabled,
      tooltipMessage,
    };
  }

  const alreadyExist = step.tasks.find((t) => t.content === ConnectionTaskTypes.Check);
  // Prevent reload
  if (alreadyExist) step.tasks.pop();
  step.tasks.push(checkTask);
  return step;
};

export const finalizeConnectionBehaviour = async (
  _connection: Connection,
  userId: string,
): Promise<Connection> => {
  const connection = await asyncMapConnectionTasks(
    _connection,
    async (task, step) => {
      let isTaskEnabled = false;
      let tooltipMessage;
      if (task.config_task_id) {
        isTaskEnabled = !hasValidators(task.owners!, 'task_id', task.config_task_id)
          || isUserValidator(
            task.owners!,
            userId,
            { key: 'task_id', matcher: task.config_task_id },
          );
      }
      if (!step.visible || _connection.status === ConnectionStatus.UNSEEN) {
        isTaskEnabled = false;
      }
      if (
        _connection.steps
        && step.step > 1
        && !_connection.steps[step.step - 2]?.validated_at
        && (task.content === ConnectionTaskTypes.CustomField
          || task.content === ConnectionTaskTypes.Form)
      ) {
        isTaskEnabled = false;
        tooltipMessage = TooltipMessage.TaskNotReady;
      }
      const contentUpdate = await buildTaskUpdates(task);
      return {
        ...task,
        ...contentUpdate,
        tooltipMessage,
        disabled: !isTaskEnabled,
      };
    },
  );

  const steps = await Promise.all(
    connection.steps.map((step) => buildExtraStepValidation(step, connection, userId)),
  );
  return {
    ..._connection,
    steps,
  };
};

export const buildTaskStatus = (task: ConnectionTask): TaskStatusFunctionReturn => {
  if (task.rejected_at) {
    return {
      icon: invalid,
      color: AWColors.Red,
      status: i18n.t('Status.rejected'),
    };
  }
  if (task.done_at && !task.validated_at) {
    return {
      icon: usersCheck,
      color: AWColors.Orange,
      status: i18n.t(
        task.content && TASK_ADDWORKING_VALIDATION.includes(task.content)
          ? 'Connection.addworkingPendingValidation' : 'Connection.addworkingPendingValidation',
      ),
    };
  }
  if (task.validated_at) {
    return {
      icon: valid,
      color: AWColors.Green,
      status: i18n.t('Status.validated'),
    };
  }
  return {
    icon: pending,
    color: AWColors.Grey,
    status: i18n.t('Status.pending'),
  };
};

export const initConnections = async (enterpriseId: string) => {
  const res: {
    connections: Connection[],
    connectionConfig: ConnectionConfig[],
  } = {
    connections: [],
    connectionConfig: [],
  };
  const [connectionsRes, configOptionsRes] = await Promise.all([
    getEnterpriseConnections(enterpriseId),
    getConnectionConfigs({
      fields: 'id,title',
      search: {
        status: 2, // Validated
      },
    }),
  ]);
  if (connectionsRes.success) {
    res.connections = connectionsRes.data.sort((a, b) => (
      new Date(b.updated_at as string).getTime() - new Date(a.updated_at as string).getTime()
    ));
  }
  if (configOptionsRes.success) {
    res.connectionConfig = configOptionsRes.data;
  }
  return res;
};

export const filterConnections = (_connections: Connection[], filter: ConnectionFilter) => {
  const connections = _connections.filter(
    (connection) => (
      connection.provider_name?.toLowerCase().includes(filter.search || '')
      && (
        !filter.configOptions?.length
        || filter.configOptions.includes(connection.config_title as string)
      )
      && (
        !filter.statuses?.length
        || filter.statuses.includes(connection.status as string)
      )
    ),
  );
  return connections;
};

export const buildNotifFromInvitations = (
  invitationsRes: ApiResponse<InvitationRes>[],
) => {
  const res = invitationsRes.reduce((acc, inv) => {
    if (inv.success) {
      acc.success.push(inv.data.email);
    } else if (inv.details.code === InvitationResError.UserAlreadyMember) {
      acc.failed.push(inv.details.email);
    }
    return acc;
  }, { success: [] as string[], failed: [] as string[] });

  const alert = {
    message: '',
    variant: 'success',
    show: true,
  };

  if (res.success.length) {
    alert.message = i18n.t(
      'NewConnection.invitationSuccess',
      'Votre invitation a bien été envoyée à {{emails}}',
      { emails: res.success.join(', ') },
    );
  }

  if (res.failed.length) {
    const textOption = !res.success.length ? 'text' : 'secondaryText';
    alert[textOption] = res.failed.length > 1 ? i18n.t(
      'NewConnection.invitationsFailed',
      "{{emails}} sont déjà membres de l'entreprise",
      { emails: res.failed.join(', ') },
    ) : i18n.t(
      'NewConnection.invitationFailed',
      "{{email}} est déjà membre de l'entreprise",
      { email: res.failed[0] },
    );
  }

  return alert;
};

export const buildConnectionFailedMessage = (errorCode: string, enterpriseName: string) => {
  if (errorCode === NewConnectionError.ConnectionAlreadyExist) {
    return {
      message: i18n.t(
        'NewConnection.alreadySent',
        'Une invitation a déjà été envoyée à {{enterprise}}',
        { enterprise: enterpriseName },
      ),
      variant: 'danger',
    };
  }
  if (errorCode === NewConnectionError.PartnershipAlreadyPartner) {
    return {
      message: i18n.t(
        'NewConnection.alreadyPartner',
        'Vous êtes déjà en relation avec {{enterprise}}',
        { enterprise: enterpriseName },
      ),
      variant: 'danger',
    };
  }
  return null;
};

/* ************************************************************************** */
/* Handle user rights (as step checkers, task using or connection validation) */
/* ************************************************************************** */

const getRefIdByConnectionUserType = (type: ConnectionUserType) => {
  switch (type) {
    case ConnectionUserType.Checker:
      return 'step_id';
    case ConnectionUserType.Owner:
      return 'task_id';
    default:
      return 'config_id';
  }
};

export const getUserWithRights = async (
  callbackApi: CallbackSafeFetch,
  type: ConnectionUserType,
  { params, config }: {
    params: Record<string, string>,
    config: HookApiConfig
  },
) => {
  const res = await callbackApi(config, params);
  if (res.hasError() || (res.success && !res.data?.length)) return [];
  const usersRes = await callbackApi(
    getUsersConfig,
    {},
    {
      fields: ['id', 'firstname', 'lastname', 'email'],
      search: {
        id: {
          $or: map(res.data, 'user_id'),
        },
      },
    },
  );
  if (usersRes.hasError()) return [];
  return res.data?.map((userRight) => {
    const user = usersRes.data.find((u) => u.id === userRight.user_id);
    return ({
      ...user,
      type,
      ref: userRight[getRefIdByConnectionUserType(type)],
    })
      || [];
  });
};

export const fillConnectionWithUsers = (
  connection: Connection,
  users: Array<User & { type: ConnectionUserType, ref: string }>,
): Connection => {
  const fillSteps = (step: ConnectionStep) => ({
    ...step,
    checkers: users.filter((u) => (
      u.type === ConnectionUserType.Checker && step.config_step_id === u.ref
    )),
  });

  const fillTasks = (task: ConnectionTask) => ({
    ...task,
    owners: users.filter((u) => (
      u.type === ConnectionUserType.Owner && task.config_task_id === u.ref
    )),
  });
  const getValidators = (c: Connection) => (
    users.filter((u) => (
      u.type === ConnectionUserType.Validator && c.config_id === u.ref
    ))
  );
  const _connection = cloneDeep(connection);
  return {
    ..._connection,
    validators: getValidators(_connection),
    steps: _connection.steps?.map((step) => ({
      ...(fillSteps(step)),
      tasks: step.tasks.map(fillTasks),
    })),
  };
};
