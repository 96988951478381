import React from 'react';
import {
  useRouteMatch,
  Switch,
  Route,
} from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import ApprovalContextProvider from '@customer/contexts/approval.context';
import Approvals from '@customer/views/Approvals/Approvals';
import NewApprovalRoutes from '@customer/routes/newApprovalRoutes';
import ApprovalContainer from '@customer/views/Approvals/ApprovalContainer/ApprovalContainer';

const ApprovalRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <Approvals />
      </ProtectedRoute>
      <Route path={`${path}/new`}>
        <NewApprovalRoutes />
      </Route>
      <ApprovalContextProvider>
        <ProtectedRoute path={`${path}/:approvalId`} exact>
          <ApprovalContainer />
        </ProtectedRoute>
      </ApprovalContextProvider>
    </Switch>
  );
};

export default ApprovalRoutes;
