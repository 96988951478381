import { ApiResponse, safeFetch } from '@root/helpers/response-handler';
import { ApprovalDocument, ApprovalDocumentModel } from '@root/interfaces/approvalDocument.interface';

const baseUrl = `${process.env.REACT_APP_URL_DOCUMENT_API}/v1/document/customer`;

export const getAllApprovalDocumentModels = (
  enterpriseId: string,
): Promise<ApiResponse<ApprovalDocumentModel[]>> => safeFetch(`${baseUrl}/${enterpriseId}/approvals/documents/models-info`);

export const getApprovalDocumentModelByS3Id = (modelS3Id: string): Promise<ApiResponse<string>> => safeFetch(`${baseUrl}/approvals/documents/${modelS3Id}`);

export const createApprovalDocument = (body: ApprovalDocument, approvalId: string): Promise<ApiResponse> => safeFetch(`${baseUrl}/approvals/${approvalId}/documents`, {
  method: 'POST',
  body: JSON.stringify(body),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const updateApprovalDocument = (body: ApprovalDocument, approvalId: string): Promise<ApiResponse> => safeFetch(`${baseUrl}/approvals/${approvalId}/documents`, {
  method: 'PUT',
  body: JSON.stringify(body),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});
