import React, {
  useMemo, useState, useEffect,
} from 'react';
import { Form } from 'react-bootstrap';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';
import LabelValue from '@customer/components/LabelValue/LabelValue';
import {
  formatSiret,
  numberToCurrency,
  toStringRange,
} from '@root/helpers/utils';
import { LegendreProvider } from '@customer/interfaces/legendre.interface';
import {
  EmployeeNumber,
  BusinessTurnover,
  Enterprise,
  ComplianceCount,
} from '@root/interfaces/enterprise.interface';
import { Partnership } from '@customer/interfaces/provider.interface';
import { getIdentificationLabel } from '@root/helpers/enterprise.helper';
import modif from '@root/assets/icon_modif.svg';
import check from '@root/assets/check-green-button.svg';
import plus from '@root/assets/plus.svg';
import { updatePartnershipConfig } from '@root/api-configs/enterprise.api.config';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import AWButton from '@root/components/AWButtons/AWButton';
import useSafeFetch from '@root/hooks/useSafeFetch';

interface Props {
  enterprise?: Enterprise & LegendreProvider,
  businessTurnover?: BusinessTurnover,
  employeeNumber?: EmployeeNumber,
  complianceCount?: ComplianceCount
  partnership?: Partnership
}

enum NumberValueType {
  Declarative = 'declarative',
  Official = 'official',
}

const EnterpriseGeneral = ({
  enterprise,
  businessTurnover,
  employeeNumber,
  complianceCount,
  partnership,
}: Props) => {
  const { t } = useTranslation();
  const { REACT_APP_HOST_URL } = process.env;
  const { waitWithLoad, isLoading } = useLoadingPromise();
  const [
    optionsVendorExternalId,
    setOptionsVendorExternalId,
  ] = useState<{
    error: boolean,
    messageError: JSX.Element,
    previousValue: string | null,
    value: string,
    editMode: boolean
  }>({
    error: false,
    messageError: <div />,
    previousValue: null,
    value: '',
    editMode: false,
  });

  const updatePartnership = useSafeFetch<Partnership>(updatePartnershipConfig).callApi;

  useEffect(() => {
    if (partnership?.vendor_external_id) {
      setOptionsVendorExternalId(
        {
          ...optionsVendorExternalId,
          value: partnership.vendor_external_id,
          previousValue: partnership.vendor_external_id,
        },
      );
    }
  }, [partnership?.vendor_external_id]);

  const address = useMemo((): string => {
    if (enterprise?.address) {
      return compact([
        enterprise.address.address,
        enterprise.address.additionnal_address,
      ]).join(' ');
    }
    return '';
  }, [enterprise?.address]);

  const addressCity = useMemo((): string => {
    if (enterprise?.address) {
      return compact([
        enterprise.address.zipcode,
        enterprise.address.town,
      ]).join(' ');
    }
    return '';
  }, [enterprise?.address]);

  const hasBusinessTurnover = (type: NumberValueType) => !!(
    businessTurnover && businessTurnover[`${type}_amount`]
  );

  const hasEmployeesNumber = (type: NumberValueType) => !!(
    employeeNumber && employeeNumber[`${type}_range_id`] && employeeNumber[`${type}_min`]
  );

  const updateVendorExternalId = async () => {
    if (optionsVendorExternalId.editMode
      && optionsVendorExternalId.value !== (optionsVendorExternalId?.previousValue || '')) {
      const res = await waitWithLoad(updatePartnership({
        providerId: partnership?.provider_id,
        customerId: partnership?.customer_id,
        body: {
          vendor_external_id: !optionsVendorExternalId.value ? null : optionsVendorExternalId.value,
        },
      }));
      if (res?.hasError()) {
        const { provider_name, vendor_id } = res.details;
        const messageError = (
          <span className="text-danger">
            {provider_name && vendor_id ? (
              <>
                {t('Enterprise.Form.Error.idAlreadyUsed')}
                <a
                  href={`${REACT_APP_HOST_URL}/customer/providers/${vendor_id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-dark"
                >
                  {` ${provider_name}`}
                </a>
              </>
            ) : (
              t('Error.UnknownError')
            )}
          </span>
        );
        setOptionsVendorExternalId({
          ...optionsVendorExternalId,
          error: true,
          messageError,
        });
      } else {
        setOptionsVendorExternalId({
          ...optionsVendorExternalId,
          previousValue: optionsVendorExternalId.value,
          editMode: !optionsVendorExternalId.editMode,
        });
      }
    } else {
      setOptionsVendorExternalId({
        ...optionsVendorExternalId,
        editMode: !optionsVendorExternalId.editMode,
      });
    }
  };

  const resetVendorExternalId = () => {
    setOptionsVendorExternalId({
      ...optionsVendorExternalId,
      editMode: false,
      previousValue: null,
      value: optionsVendorExternalId.previousValue || partnership?.vendor_external_id || '',
      error: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (optionsVendorExternalId.error) {
      resetVendorExternalId();
    } else {
      updateVendorExternalId();
    }
  };

  return (
    <div className="enterprise-info">
      <LabelValue keyName={t('Enterprise.name', "Nom d'enterprise")}>
        <span className="enterprise-name">{enterprise?.name}</span>
      </LabelValue>
      <LabelValue keyName={getIdentificationLabel(enterprise?.country) || ''}>
        {formatSiret(
          enterprise?.enterprise_identification_number
          || enterprise?.identification_number || '',
        )}
      </LabelValue>
      <LabelValue keyName={t('Enterprise.address', 'Adresse')}>
        {address}
        <br />
        {addressCity}
      </LabelValue>

      {
        enterprise?.main_activity_code ? (
          <LabelValue keyName={t('Enterprise.mainActivityCode', 'Code APE')}>
            {enterprise?.main_activity_code}
          </LabelValue>
        ) : ''
      }
      {
        enterprise?.tax_identification_number ? (
          <LabelValue keyName={t('Enterprise.vat', 'TVA')}>
            {enterprise?.tax_identification_number}
          </LabelValue>
        ) : ''
      }
      {
        enterprise?.registration_town ? (
          <LabelValue keyName={t('Enterprise.registrationTown', "Ville d'immatriculation")}>
            {enterprise?.registration_town}
          </LabelValue>
        ) : ''
      }
      <LabelValue keyName={t('BusinessTurnover.title')}>
        {hasBusinessTurnover(NumberValueType.Declarative) ? (
          `${numberToCurrency(businessTurnover!.declarative_amount!, businessTurnover!.currency || 'EUR')} (${businessTurnover!.year}) (${t('Data.declarative')})`
        ) : ''}
        {hasBusinessTurnover(NumberValueType.Declarative)
          && hasBusinessTurnover(NumberValueType.Official) ? <br /> : ''}
        {!hasBusinessTurnover(NumberValueType.Declarative)
          && !hasBusinessTurnover(NumberValueType.Official) ? '-' : ''}
        {hasBusinessTurnover(NumberValueType.Official) ? (
          `${numberToCurrency(businessTurnover!.official_amount!, businessTurnover!.currency || 'EUR')} (${businessTurnover!.year}) (${t('Data.official')})̀`
        ) : ''}
      </LabelValue>
      <LabelValue keyName={t('Enterprise.employeeNumber', "Nombre d'employés")}>
        {hasEmployeesNumber(NumberValueType.Declarative) ? (
          `${toStringRange(employeeNumber!.declarative_min!, employeeNumber!.declarative_max)} (${employeeNumber!.year}) (${t('Data.declarative')})`
        ) : ''}
        {hasEmployeesNumber(NumberValueType.Declarative)
          && hasEmployeesNumber(NumberValueType.Official) ? <br /> : ''}
        {!hasEmployeesNumber(NumberValueType.Declarative)
          && !hasEmployeesNumber(NumberValueType.Official) ? '-' : ''}
        {hasEmployeesNumber(NumberValueType.Official) ? (
          `${toStringRange(employeeNumber!.official_min!, employeeNumber!.official_max)} (${employeeNumber!.year}) (${t('Data.official')})`
        ) : ''}
      </LabelValue>
      {
        complianceCount ? (
          <LabelValue keyName={t('Enterprise.legalConformity')}>
            {complianceCount?.legal_total === complianceCount?.legal_validated
              ? t('CardEnterprise.ok', 'ok') : t('Status.inProgress')}
          </LabelValue>
        ) : ''
      }
      {
        complianceCount ? (
          <LabelValue keyName={t('Enterprise.extendedConformity')}>
            {complianceCount?.business_total === complianceCount?.business_validated
              ? t('CardEnterprise.ok', 'ok') : t('Status.inProgress')}
          </LabelValue>
        ) : ''
      }
      {
        partnership ? (
          <>
            <LabelValue keyName={t('Enterprise.status')}>
              {partnership?.activity_ends_at && new Date() > new Date(partnership?.activity_ends_at)
                ? t('Enterprise.inactive') : t('Enterprise.active')}
            </LabelValue>
            <LabelValue keyName={t('Enterprise.personalId')}>
              <Form className="d-flex align-items-center" onSubmit={handleSubmit}>
                {(optionsVendorExternalId.editMode)
                  ? (
                    <>
                      <Form.Control
                        id="input-vendor-external-id"
                        onChange={(e) => setOptionsVendorExternalId(
                          {
                            ...optionsVendorExternalId,
                            value: e?.target?.value.trim() || '',
                            error: false,
                          },
                        )}
                        value={optionsVendorExternalId.value}
                        size="sm"
                      />

                      {optionsVendorExternalId.error
                        && (
                          <Form.Text id="input-vendor-external-id" className="text-red">
                            {optionsVendorExternalId.messageError}
                          </Form.Text>
                        )}
                    </>
                  ) : <div className="text-break">{optionsVendorExternalId.value?.length ? optionsVendorExternalId.value : '-'}</div>}

                <AWButton
                  backgroundColor="transparent"
                  type="submit"
                  isLoading={isLoading}
                  className="p-0 ms-3"
                >
                  {
                    (optionsVendorExternalId.error
                      ? (
                        <img
                          src={plus}
                          alt="reset personal id"
                          height={20}
                          width={20}
                          className="rotate-45"
                        />
                      )
                      : (
                        <img
                          src={(optionsVendorExternalId.editMode) ? check : modif}
                          alt="edit personal id"
                        />
                      )
                    )
                  }
                </AWButton>
              </Form>
            </LabelValue>
          </>
        ) : null
      }

    </div>
  );
};

EnterpriseGeneral.defaultProps = {
  enterprise: null,
  businessTurnover: null,
  employeeNumber: null,
  complianceCount: null,
  partnership: null,
};

export default EnterpriseGeneral;
