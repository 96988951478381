import React from 'react';
import {
  useRouteMatch,
  Switch,
} from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import ApprovalProviderContextProvider from '@provider/contexts/approvalProvider.context';
import ApprovalsProvider from '@provider/views/Approvals/ApprovalsProvider';
import ApprovalContainer from '@provider/views/Approvals/ApprovalContainer/ApprovalContainer';

const ApprovalProviderRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={path} exact>
        <ApprovalsProvider />
      </ProtectedRoute>
      <ApprovalProviderContextProvider>
        <ProtectedRoute path={`${path}/:approvalId`} exact>
          <ApprovalContainer />
        </ProtectedRoute>
      </ApprovalProviderContextProvider>
    </Switch>
  );
};

export default ApprovalProviderRoutes;
