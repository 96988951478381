import React, { useContext, useEffect, useState } from 'react';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import { initApprovals as initApprovalsBuilder } from '@provider/views/Approvals/approvals.provider.builder';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import CardApproval from '@provider/components/CardApproval/CardApproval';
import { ApprovalProvider, ApprovalFilter } from '@root/interfaces/approval.interface';
import Tabs from '@provider/components/Tabs/Tabs';
import { AlertContext } from '@root/contexts/alert.context';
import {
  filterByContact, filterByIdentity, filterByKeyword, filterByStatus,
} from '@provider/services/approval.service';
import { useHistory } from 'react-router-dom';
import ApprovalsProviderFilters from '@provider/views/Approvals/ApprovalContainer/ApprovalsFilters/ApprovalsProviderFilters';

const ApprovalsProvider = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const history = useHistory();

  const tabs: string[] = [
    t('ApprovalsFilters.tabAll', 'TOUTES'),
    t('ApprovalsFilters.waitingValidation', 'EN ATTENTE DE VALIDATION'),
    t('ApprovalsFilters.waitingMySignature', 'EN ATTENTE DE MA SIGNATURE'),
  ];

  const [activeTab, setActiveTab] = useState<string>(t('ApprovalsFilters.tabAll', 'TOUTES'));
  const [approvalList, setApprovalList] = useState<ApprovalProvider[]>([]);
  const [approvalsToDisplay, setApprovalsToDisplay] = useState<ApprovalProvider[]>([]);
  const [startFiltersSearch, setStartFiltersSearch] = useState<boolean>(false);
  const [approvalFilters, setApprovalFilters] = useState<ApprovalFilter>({});
  const [loadingBtn, setLoadingBtn] = useState<string>();
  const { setNotif } = useContext(AlertContext);

  const { waitWithLoad, isLoading } = useLoadingPromise();
  const isBtnLoading = (btn: string) => isLoading && loadingBtn === btn;

  const initApprovals = async () => {
    if (user.currentEnterprise?.id) {
      const { approvals, isSessionExpired } = await waitWithLoad(
        initApprovalsBuilder(user.currentEnterprise.id),
      );
      setApprovalList(approvals);
      setApprovalsToDisplay(approvals);
      if (isSessionExpired) setNotif(t('Punchout.expired', 'Votre session est expirée'));
    }
  };

  useEffect(() => {
    initApprovals();
  }, [user.currentEnterprise?.id]);

  const handleFiltersChange = (fieldName: keyof ApprovalFilter) => (e) => {
    setApprovalFilters({ ...approvalFilters, [fieldName]: e.target?.value || e.value });
  };

  const handleFiltersSearch = () => {
    setApprovalsToDisplay(approvalList);
    setStartFiltersSearch((prevValue) => !prevValue);
  };

  const applyFilters = () => {
    let filteredApprovals: ApprovalProvider[] = approvalList;

    if (approvalFilters.identity) {
      filteredApprovals = filterByIdentity(approvalFilters.identity!, filteredApprovals);
    }

    if (approvalFilters.contact) {
      filteredApprovals = filterByContact(approvalFilters.contact!, filteredApprovals);
    }

    if (approvalFilters.keyword) {
      filteredApprovals = filterByKeyword(approvalFilters.keyword!, filteredApprovals);
    }

    if (approvalFilters.status) {
      filteredApprovals = filterByStatus(approvalFilters.status, filteredApprovals);
    }

    setApprovalsToDisplay(filteredApprovals);
  };

  useEffect(() => {
    applyFilters();
  }, [startFiltersSearch]);

  const applyTabFilters = () => {
    let filteredApprovals: ApprovalProvider[] = approvalList;
    switch (activeTab) {
      case t('ApprovalsFilters.tabVariables', 'EN ATTENTE DE VALIDATION'):
        filteredApprovals = filterByStatus('waitingMyValidation', filteredApprovals);
        break;
      case t('ApprovalsFilters.tabSignatures', 'EN ATTENTE DE MA SIGNATURE'):
        filteredApprovals = filterByStatus('providerSignatureMissing', filteredApprovals);
        break;
      default:
        filteredApprovals = approvalList;
        break;
    }
    setApprovalsToDisplay(filteredApprovals);
  };

  useEffect(() => {
    applyTabFilters();
  }, [activeTab]);

  return (
    <AWContainer className="approvals" isLoading={isLoading}>
      <AWContainer.Main
        title={t('Approvals.title', "Demandes d'agrément")}
        titleSideCounter={approvalsToDisplay.length.toString()}
      >
        <Breadcrumb>
          <BreadcrumbItem>{t('Approvals.myActivity', 'Mon activité')}</BreadcrumbItem>
          <BreadcrumbItem active>{t('Approvals.approvals', "Demandes d'agrément")}</BreadcrumbItem>
        </Breadcrumb>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        {approvalsToDisplay.map((approval) => (
          <CardApproval key={approval.id} approval={approval} onClick={() => history.push(`/provider/approvals/${approval.id}`)} />
        ))}
      </AWContainer.Main>
      <AWContainer.Side>
        <ApprovalsProviderFilters
          approvalFilters={approvalFilters}
          setApprovalFilters={setApprovalFilters}
          handleFiltersSearch={handleFiltersSearch}
          onChange={handleFiltersChange}
          isBtnLoading={isBtnLoading}
          handleLoadingBtn={setLoadingBtn}
        />
      </AWContainer.Side>
    </AWContainer>
  );
};

export default ApprovalsProvider;
