import React, {
  useEffect, useState, useContext,
} from 'react';
import { useTranslation } from 'react-i18next';

import AWContainer from '@root/components/AWContainer/AWContainer';
import BlockFilter from '@customer/components/BlockFilter/BlockFilter';
import { Block as IBlock, NewBlock as INewBlock } from '@customer/interfaces/block.interface';
import Block from '@customer/components/Block/Block';
import BlockInline from '@customer/components/Block/BlockInline';
import BlockEditorForm from '@customer/components/BlockEditorForm/BlockEditorForm';
import BlockList from '@customer/components/BlockList/BlockList';
import useSafeFetch from '@root/hooks/useSafeFetch';
import {
  getBlocksConfig, createBlockConfig, updateBlockConfig, deleteBlockConfig, searchBlocksConfig,
} from '@root/api-configs/blocks.api.config';
import { UserContext } from '@root/contexts/user.context';
import AWButton from '@root/components/AWButtons/AWButton';
import { DEFAULT_PAGE_SIZE } from '@root/helpers/constants.helper';

const Library = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [blockToUpdate, setBlockToUpdate] = useState<IBlock | null>(null);
  const [isViewList, setViewList] = useState<boolean>(true);
  const [isOnEdit, setIsOnEdit] = useState<boolean>(false);

  // PAGINATION
  const [page, setPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(false);

  // CALL API
  const {
    data: blocks,
    setData: setBlocks,
    callApi: fetchBlocks,
    loading,
  } = useSafeFetch<IBlock[]>(getBlocksConfig, []).toObject;
  const createBlockService = useSafeFetch<IBlock>(createBlockConfig).callApi;
  const updateBlockService = useSafeFetch<IBlock>(updateBlockConfig).callApi;
  const deleteBlockService = useSafeFetch<IBlock>(deleteBlockConfig).callApi;
  const searchBlockService = useSafeFetch<IBlock[]>(searchBlocksConfig).callApi;

  const getBlocks = async () => {
    const res = await fetchBlocks(
      {
        enterpriseId: user.currentEnterprise?.id,
      },
      {
        page,
        fields: 'block_id,block_description,block_type,block_content,block_enterprise_id,block_created_at',
        sort: '-block_created_at',
        page_size: DEFAULT_PAGE_SIZE,
      },
    );
    if (res?.success) {
      setBlocks(page === 1 ? res.data : [...blocks, ...res.data]);
      setHasMorePages(res.data?.length === DEFAULT_PAGE_SIZE);
    }
  };

  useEffect(() => {
    getBlocks();
  }, [page]);

  const handleBlockUpdate = (id: string) => {
    const selectedBlock = blocks.find((b) => b.id === id);
    if (selectedBlock) {
      setBlockToUpdate(selectedBlock);
      setIsOnEdit(true);
    }
  };

  const handleBlockCreate = () => {
    setBlockToUpdate(null);
    setIsOnEdit(true);
  };

  const handleFiltersSearch = async (term: string, _page = 1) => {
    if (term) {
      if (term !== searchTerm) {
        setBlocks([]);
        setSearchPage(1);
        setPage(1);
        setSearchTerm(term);
      } else {
        setSearchPage(_page);
      }

      const response = await searchBlockService({
        enterpriseId: user.currentEnterprise?.id,
        body: { searchTerm: term },
        query: {
          page: _page,
          page_size: DEFAULT_PAGE_SIZE,
        },
      });

      if (response?.success){
        setBlocks((prevResults) => (_page === 1
          ? response.data
          : [...prevResults, ...response.data]));
        setHasMorePages(response.data?.length === DEFAULT_PAGE_SIZE);
      }
    } else {
      setPage(1);
      getBlocks();
    }
  };

  const createNewBlock = async (content: string, description: string, type: string) => {
    const newBlock: INewBlock = {
      type,
      description,
      content,
    };
    await createBlockService({ enterpriseId: user.currentEnterprise?.id, body: newBlock });
    if (page === 1){
      getBlocks();
    }
    setPage(1);
  };

  const updateBlock = async (content: string, description: string, type: string) => {
    const updatedBlock: IBlock = {
      ...blockToUpdate!,
      type,
      description,
      content,
    };
    const res = await updateBlockService({
      enterpriseId: user.currentEnterprise?.id,
      id: blockToUpdate?.id,
      body: updatedBlock,
    });
    if (res?.success) {
      setBlocks((blockList) => blockList.map((b) => (b.id === blockToUpdate?.id ? res.data : b)));
    }
  };

  const deleteBlock = async (blockId: string) => {
    await deleteBlockService({ enterpriseId: user.currentEnterprise?.id, id: blockId });
    setBlocks((blockList) => blockList.filter((b) => b.id !== blockId));
  };

  const handleBlockSave = async (content: string, description: string, type: string) => {
    if (!blockToUpdate) {
      await createNewBlock(content, description, type);
    } else {
      await updateBlock(content, description, type);
    }
    setSearchTerm('');
    setIsOnEdit(false);
  };

  const loadMore = async () => {
    if (searchTerm) {
      const nextPage = searchPage + 1;
      setSearchPage((p) => p + 1);
      await handleFiltersSearch(searchTerm, nextPage);
    } else {
      setPage((p) => p + 1);
    }
  };

  const handleReset = () => {
    setPage(1);
    setSearchPage(1);
    setSearchTerm('');
    setBlocks([]);
  };

  return (
    <AWContainer
      isLoading={loading}
    >
      <AWContainer.Main
        title={t('Library.library', 'Bibliothèque de blocs')}
      >
        {isOnEdit ? (
          <BlockEditorForm
            block={blockToUpdate || null}
            saveChanges={handleBlockSave}
            cancelChanges={() => setIsOnEdit(false)}
          />
        ) : (
          <>
            <BlockList
              handleBlockCreate={handleBlockCreate}
              isViewList={isViewList}
              setViewList={setViewList}
              blocks={blocks}
              handleBlockUpdate={handleBlockUpdate}
              deleteBlock={deleteBlock}
              BlockComponent={isViewList ? BlockInline : Block}
            />
            {
            hasMorePages ? (
              <AWButton
                type="button"
                className="rounded"
                backgroundColor="white"
                color="black"
                loaderColor="secondary"
                onClick={loadMore}
              >
                {t('Library.displayMoreBlocks', 'Afficher plus de blocs')}
              </AWButton>
            ) : null
          }
          </>
        )}
      </AWContainer.Main>

      <AWContainer.Side>
        <h3>{t('Library.filter', 'Filtrer')}</h3>
        <BlockFilter
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleFiltersSearch={handleFiltersSearch}
          handleReset={handleReset}
        />
      </AWContainer.Side>
    </AWContainer>
  );
};

export default Library;
