import React, { useContext, useEffect, useState } from 'react';
import { ApprovalProviderContext } from '@provider/contexts/approvalProvider.context';
import { ILabelValueCard } from '@root/interfaces/labelValueCard.interface';
import { useTranslation } from 'react-i18next';
import LabelValueCard from '@root/components/LabelValueCard/LabelValueCard';
import { ApprovalStatusEnum } from '@root/interfaces/approval.interface';
import { mimeType } from '@root/helpers/utils';
import AWButton from '@root/components/AWButtons/AWButton';
import { updateApproval } from '@root/services/approval.service';
import { Modal } from 'react-bootstrap';
import { addDocument } from '@root/services/yousign.service';
import { setApprovalInformationsLabelValueDatas } from '@root/helpers/approval.helper';

const ApprovalInformations = () => {
  const { approvalToDisplay } = useContext(ApprovalProviderContext);
  const { t } = useTranslation();
  const [labelValue, setLabelValue] = useState<ILabelValueCard[]>([]);
  const [modal, setModal] = useState<{
    isOpen: boolean;
    title?: string;
  }>({ isOpen: false });

  // modal handling functions
  const closeModal = () => setModal({ isOpen: false });

  useEffect(() => {
    const labelValueDatas = setApprovalInformationsLabelValueDatas(approvalToDisplay);
    setLabelValue(labelValueDatas);
  }, [approvalToDisplay]);

  const activateApproval = async () => {
    await updateApproval({
      id: approvalToDisplay.id,
      status: ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION,
    }, approvalToDisplay.id)
      .then((res) => {
        if (res.success) {
          setModal({ isOpen: true, title: 'Validation' });
        } else {
          setModal({ isOpen: true, title: 'Erreur' });
        }
      });
    if (approvalToDisplay.yousign_signature_request_id) {
      return addDocument(approvalToDisplay.yousign_signature_request_id, { fileId: approvalToDisplay.document_id, nature: 'signable_document' });
    }
    return '';
  };

  return (
    <>
      <div>
        <LabelValueCard labelValues={labelValue} />
      </div>
      {approvalToDisplay.mime_type === mimeType.pdf
        ? (
          <div className="approval-information">
            <AWButton
              className="approval-information__validation-button"
              onClick={() => activateApproval()}
            >
              {t('validationButton', "Valider la demande d'agrément")}
            </AWButton>
          </div>
        ) : ''}
      <Modal show={modal.isOpen} onHide={closeModal} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="approvals-information__modal">
            {modal.title === 'Validation' ? (
              <>
                {t('Approvals.informationValidModal', "La demande d'agrément a bien été validée")}
              </>
            )
              : (
                <>
                  {t('Approvals.informationErrorModal', 'Une erreur est survenue')}
                </>
              )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApprovalInformations;
