import React, { useContext, useEffect, useState } from 'react';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { Contract, ContractFilter } from '@root/interfaces/contract.interface';
import Tabs from '@root/components/TabsMenu/Tabs';
import { getContractsConfig } from '@root/api-configs/contracts.api.config';
import { PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';
import { UserContext } from '@root/contexts/user.context';
import useSafeFetch from '@root/hooks/useSafeFetch';
import { randString } from '@root/helpers/utils';
import CardContract from '@root/components/Cards/CardContract/CardContract';
import { contractTabsViews } from '@root/helpers/contract.helper';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import ContractFilters from './ContractFilters/ContractFilters';

const Contracts = () => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const { user } = useContext(UserContext);
  const [initContracts, contracts] = useSafeFetch<Contract[]>(getContractsConfig, []);
  const [activeTab, setActiveTab] = useState(contractTabsViews[0]);
  const [contractFilters, setContractFilters] = useState<ContractFilter>({});
  const [loadingBtn, setLoadingBtn] = useState<string>();
  const [partiesList, setPartiesList] = useState<Array<{ value: string, label: string }>>();
  const [partiesExist, setPartiesExist] = useState<boolean>(true);

  const { isLoading } = useLoadingPromise();
  const isBtnLoading = (btn: string) => isLoading && loadingBtn === btn;
  const handleFiltersSearch = () => {
    if (user.currentEnterprise?.id) {
      contractFilters.enterprise_id = user.currentEnterprise.id;
      initContracts({
        query: {
          enterprise_id: user.currentEnterprise?.id,
          ...(contractFilters.name && { name: contractFilters.name }),
          ...(contractFilters.states && { states: contractFilters.states }),
          ...(contractFilters.party_id && { party_id: contractFilters.party_id }),
        },
      });
    }
  };

  const handleFiltersChange = (fieldName: keyof ContractFilter) => (e) => {
    if (fieldName === 'states' && e.value !== ''){
      const states: string[] = [];
      states.push(e.value);
      setContractFilters({ ...contractFilters, states });
    } else {
      delete contractFilters[fieldName];
      setContractFilters({ ...contractFilters, [fieldName]: e.target?.value || e.value });
    }
  };

  const getContracts = async () => {
    if (user.currentEnterprise?.id) {
      initContracts({
        query: {
          enterprise_id: user.currentEnterprise.id,
        },
      });
    }
  };

  const getParties = () => {
    const partiesTab = new Set<string>();
    const _parties = contracts
      .flatMap(({ parties = [] }) => parties.filter(({ enterprise_id }) => {
        const alreadyExists = partiesTab.has(enterprise_id);
        partiesTab.add(enterprise_id);
        return !alreadyExists;
      })
        .map(({ enterprise_id, enterprise_name }) => ({
          value: enterprise_id,
          label: enterprise_name,
        })));
    setPartiesList(_parties);
  };

  useEffect(() => {
    getContracts();
    setPartiesExist(false);
  }, [user?.currentEnterprise?.id]);

  useEffect(() => {
    if (!partiesExist){
      getParties();
      setPartiesExist(true);
    }
  }, [contracts]);

  const showContracts = (contractsToShow: Array<Contract>) => contractsToShow?.map((contract) => (
    <CardContract
      key={randString()}
      contract={contract}
      onClick={() => push(`${PROVIDER_BASE_PATH}/v1/contract/${contract.id}`)}
    />
  ));

  const mainContent = () => {
    switch (activeTab.key) {
      case 'amendment': {
        const amendments = contracts.filter((contract) => contract.parent_id !== null);
        return showContracts(amendments);
      }
      case 'contracts': {
        const onlyContracts = contracts.filter((contract) => contract.parent_id === null);
        return showContracts(onlyContracts);
      }
      default:
        return showContracts(contracts);
    }
  };

  return (
    <AWContainer>
      <AWContainer.Main
        title={(
          <div className="d-flex align-items-center">
            <span>
              {t('Contracts.title', 'Mes contrats')}
            </span>
          </div>
          )}
      >
        <Tabs
          tabs={contractTabsViews}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {mainContent()}
      </AWContainer.Main>
      <AWContainer.Side
        title={t('ContractFilters.filters', 'Filtrer')}
      >
        <ContractFilters
          partiesList={partiesList}
          contractFilters={contractFilters}
          setContractFilters={setContractFilters}
          handleFiltersSearch={handleFiltersSearch}
          onChange={handleFiltersChange}
          isBtnLoading={isBtnLoading}
          handleLoadingBtn={setLoadingBtn}
        />
      </AWContainer.Side>
    </AWContainer>
  );
};

export default Contracts;
