import React, {
  useEffect, useState, useContext, useMemo,
} from 'react';
import { ApprovalProviderContext } from '@provider/contexts/approvalProvider.context';
import {
  Col, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { Option } from '@root/interfaces/utils.interface';
import modelVariableRegex from '@customer/views/Approvals/NewApproval/NewApprovalSteps/utils/modelVariablesRegex';
import AWButton from '@root/components/AWButtons/AWButton';
import { goTo, mimeType } from '@root/helpers/utils';
import { useTranslation } from 'react-i18next';
import { createApprovalSignature, updateApproval, updateApprovalVariables } from '@root/services/approval.service';
import {
  ApprovalSignature, ApprovalStatusEnum, ApprovalUserTypeEnum, ApprovalVariable,
} from '@root/interfaces/approval.interface';
import { uploadToS3, deleteToS3, getUploadSignedURL } from '@root/services/file.service';
import { addDocument, addYousignSignerHelper } from '@root/services/yousign.service';
import { UserContext } from '@root/contexts/user.context';
import { ApprovalNotificationEventEnum } from '@root/interfaces/approvalNotification.interface';
import { sendApprovalEmail } from '@root/services/approvalNotification.service';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { PROVIDER_BASE_PATH } from '@root/helpers/constants.helper';

const ApprovalVariables = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const {
    approvalToDisplay,
    modelSelected,
    setModelSelected,
    modelVariableDictionary,
    setModelVariableDictionary,
  } = useContext(ApprovalProviderContext);
  const [variables, setVariables] = useState<string[]>([]);
  const [modal, setModal] = useState<{
    isOpen: boolean;
    title?: string;
  }>({ isOpen: false });

  const { waitWithLoad, isLoading } = useLoadingPromise();

  let model = modelSelected;
  const modelVariables = useMemo(() => (model.match(modelVariableRegex.modelVariable)), [model]);

  const modelVariablesFormated = useMemo<Option[]>(() => {
    if (modelVariables) {
      return modelVariables?.map((el) => ({
        label: el.replace(modelVariableRegex.replaceVariable, ''),
        disabled: !el.match(modelVariableRegex.selectedProviderVariable),
        value: '',
      }));
    }
    return [];
  }, [modelVariables]);

  const setVariablesValue = () => {
    const body: ApprovalVariable[] = [];
    const newModelVariablesFormated: Option[] = [];
    modelVariablesFormated.forEach((variable) => {
      newModelVariablesFormated.push({
        label: variable.label,
        value: modelVariableDictionary[variable.label].value,
      });
    });
    newModelVariablesFormated.forEach((variable) => {
      body.push({ variable: variable.label, value: variable.value });
      model = model.replace(`{{1.${variable.label}}}`, variable.value);
    });
    setModelSelected(model);
    return body;
  };

  const updateS3Model = async (documentId: string) => {
    // delete doc from S3
    await deleteToS3(documentId);
    // add new model to S3 with same key as the deleted doc
    if (user.currentEnterprise?.id) {
      const uploadURLRes = await getUploadSignedURL(mimeType.html, documentId);
      if (uploadURLRes.success) {
        await uploadToS3(uploadURLRes.data.signedUrl, model);
      }
      return uploadURLRes.data.key;
    }
    return '';
  };

  const addDocumentToYousign = async (fileId: string) => {
    const addYousignDocumentRes = await addDocument(
      approvalToDisplay.yousign_signature_request_id!,
      { fileId, nature: 'signable_document' },
    );
    return addYousignDocumentRes.data.id;
  };

  const addProviderAsYousignSigner = async (yousignDocumentId: string) => {
    const addProviderSignerRes = await addYousignSignerHelper(
      approvalToDisplay.yousign_signature_request_id!,
      user,
      [
        {
          // TODO: get those values from db
          // provider values only suitable for DC4 template
          document_id: yousignDocumentId,
          type: 'signature',
          height: 100,
          width: 135,
          page: 5,
          x: 7,
          y: 134,
        },
      ],
    );
    return addProviderSignerRes.data.id;
  };

  const saveApprovalSignatureInDb = async (signerId: string) => {
    const newApprovalSignature: ApprovalSignature = {
      approval_id: approvalToDisplay.id,
      enterprise_id: user.currentEnterprise?.id,
      user_id: user.id,
      signer_id: signerId,
      user_type: ApprovalUserTypeEnum.PROVIDER,
    };
    return createApprovalSignature(newApprovalSignature, approvalToDisplay.id);
  };

  const saveVariables = async (body: ApprovalVariable[]) => {
    const approvalId = approvalToDisplay.id;
    updateApprovalVariables(body, approvalId);
    const res = await updateApproval(
      {
        id: approvalToDisplay.id,
        status: ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION,
      },
      approvalToDisplay.id,
    );
    setModal({ isOpen: true, title: res.success ? t('Common.validation', 'Validation') : t('Common.error', 'Erreur') });
    setTimeout(
      () => goTo(`${PROVIDER_BASE_PATH}/approvals/${approvalId}`),
      5000,
    );
  };

  const sendProviderValidatedNotificationToCustomer = async () => {
    await sendApprovalEmail({
      event: ApprovalNotificationEventEnum.PROVIDER_VALIDATED,
      emails: [approvalToDisplay.customer_email],
      provider_enterprise: user.currentEnterprise!.name,
      provider_name: `${user.firstname} ${user.lastname}`,
    });
  };

  // modal handling functions
  const closeModal = () => setModal({ isOpen: false });

  const validate = async () => {
    const body = setVariablesValue();
    const updatedfileId = await updateS3Model(approvalToDisplay.document_id!);
    const yousignDocumentId = await addDocumentToYousign(updatedfileId);
    const providerSignerId = await addProviderAsYousignSigner(yousignDocumentId);
    await saveApprovalSignatureInDb(providerSignerId);
    await sendProviderValidatedNotificationToCustomer();
    saveVariables(body);
  };

  useEffect(() => {
    if (approvalToDisplay.mime_type === mimeType.html) {
      const modelVariablesProvider = modelVariablesFormated.filter((el) => el.disabled);
      if (modelVariablesProvider?.length) {
        setVariables(modelVariablesProvider.map((el) => el.label));
      }
      if (!Object.keys(modelVariableDictionary).length) {
        const _modelVariableDictionary = modelVariablesProvider
          .reduce((acc: Record<string, Option>, el: Option) => {
            let label = el.label.replaceAll('_', ' ');
            label = label.charAt(0).toUpperCase() + label.slice(1);
            return {
              ...acc,
              [el.label]: { value: '', label, disabled: !el.disabled },
            };
          }, {});
        setModelVariableDictionary(_modelVariableDictionary);
      }
    }
  }, [modelVariables, modelVariableDictionary, modelVariablesFormated]);

  return (
    <div className="approvals-variables">
      {approvalToDisplay.status === ApprovalStatusEnum.WAITING_PROVIDER_VALIDATION ? (
        <div className="approvals-variables__message">
          {t(
            'NewApprovalSteps.fillYourVariablesProvider',
            'Veuillez saisir les variables vous concernant dans les différents champs ci-dessous. Ceci permettra à votre sous-traitant, après validation, de saisir les siennes.',
          )}
        </div>
      ) : (
        <div className="approvals-variables__message">
          {t(
            'Approvals.variablesMessage',
            "Aucune variable à remplir pour cette demande d'agrément",
          )}
        </div>
      )}
      <div className="approvals-variables">
        {approvalToDisplay.mime_type === mimeType.html && variables.length ? (
          <div className="approvals-variables__table">
            <div className="approvals-variables__table__header">
              <Row>
                <Col>{t('Approvals.variables', 'Variables (techniques)')}</Col>
                <Col>{t('Approvals.valeurs', 'Valeurs')}</Col>
                <Col>{t('Approvals.labels', 'Labels')}</Col>
              </Row>
            </div>
            <div className="approvals-variables__table__body">
              {modelVariableDictionary
                ? variables?.map((variable) => (
                  <Row
                    key={modelVariableDictionary[variable].label}
                    className="approvals-variables__table__body__row"
                  >
                    <Col xs={4} className="approvals-variables__table__body__case">
                      <span>{variable}</span>
                    </Col>
                    <Col xs={4} className="approvals-variables__table__body__case">
                      <Form.Group>
                        <Form.Control
                          defaultValue={modelVariableDictionary[variable].value}
                          onChange={(event) => {
                            modelVariableDictionary[variable].value = event.target.value;
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={4} className="approvals-variables__table__body__case">
                      {modelVariableDictionary[variable].label}
                    </Col>
                  </Row>
                ))
                : ''}
            </div>
            <div className="approvals-variables__table__button">
              <AWButton
                marginTop="1em"
                onClick={() => waitWithLoad(validate())}
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner size="sm" animation="border" />
                ) : (
                  t('Action.validate', 'Valider')
                )}
              </AWButton>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <Modal
        show={modal.isOpen}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="approvals-variables__modal">
            {modal.title === 'Validation' ? (
              <>
                {t(
                  'Approvals.variablesModal',
                  'Toutes vos variables ont été enregistrées. Vous serez bientôt invité à signer la demande d’agrément.',
                )}
              </>
            ) : (
              <>{t('Approvals.informationErrorModal', 'Une erreur est survenue')}</>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ApprovalVariables;
