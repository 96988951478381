import { HookApiConfig } from '@root/interfaces/api-response.interface';

const baseUrl = `${process.env.REACT_APP_CONTRACT_API}/v1/contract/#userType/enterprise/#enterpriseId/blocks`;

export const getBlocksConfig: HookApiConfig = { url: baseUrl };

export const createBlockConfig: HookApiConfig = {
  url: `${baseUrl}`,
  method: 'POST',
};

export const updateBlockConfig: HookApiConfig = {
  url: `${baseUrl}/#id`,
  method: 'PUT',
};

export const deleteBlockConfig: HookApiConfig = {
  url: `${baseUrl}/#id`,
  method: 'DELETE',
};

export const searchBlocksConfig: HookApiConfig = {
  url: `${baseUrl}/search`,
  method: 'POST',
};
