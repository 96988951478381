// eslint-disable-next-line
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const PHONE_NUMBER_REGEX = /^(33\d{9}|(?!33)\d+)$/;
export const GUID_ID = '[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}';
export const URL_REGEX = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;
export const HOST_REGEX = /https?:\/\/([a-zA-Z0-9]|-|\.|:)+/;
export const ALPHA_REGEX = /^[a-zA-Z]+$/;
export const NUMERIC_REGEX = /^[0-9]+$/;
export const ALPHANUM_REGEX = /^[a-zA-Z0-9]+$/;
export const BE_IDENTIFICATION_NUMBER_REGEX = /^\d{4}\.\d{3}\.\d{3}$/;
export const DE_IDENTIFICATION_NUMBER_REGEX = /^(HRB|HRA)?\d+$/;
export const FR_IDENTIFICATION_NUMBER_REGEX = /\d{14}/;
