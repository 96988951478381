import React from 'react';
import {
  Col,
  Row,
  Form,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';

import add from '@root/assets/add.svg';
import trash from '@root/assets/trash.svg';

import { ConnectionContact } from '@root/interfaces/connection.interface';
import AWButton from '@root/components/AWButtons/AWButton';

interface Props {
  contacts: ConnectionContact[];
  isSubmitDisabled: boolean
  onSubmit: (e?: any) => void | Promise<void>,
  updateContact: (id: string, value: string, field: string) => void,
  deleteContact: (id: string) => void,
  addContact: () => void,
  checkValidationMail: (mail: string, index: number) => boolean,
  checkValidationPhoneNumber: (phoneNumber: string | undefined) => boolean,
}

const InviteRecipientForm = ({
  contacts,
  isSubmitDisabled,
  onSubmit,
  updateContact,
  deleteContact,
  addContact,
  checkValidationMail,
  checkValidationPhoneNumber,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      className="d-flex flex-column flex-grow-1"
    >
      <Row>
        <h2 className="text-dark font-style-regular font-size-20 mt-2 mb-3">
          {t('InviteRecipientForm.recipients', 'Destinataires')}
        </h2>
        {contacts.map((contact, index) => (
          <Row key={contact.id}>
            <Col md="5">
              <Form.Group className="">
                <Form.Label
                  htmlFor="new-connection-email"
                >
                  {t('InviteRecipientForm.email', 'Email')}
                </Form.Label>
                <Form.Control
                  id="email"
                  onChange={(e) => updateContact(contact?.id, e?.target?.value || '', 'email')}
                  value={contact.email}
                  isValid={checkValidationMail(contact?.email, index)}
                  isInvalid={contact?.email?.length
                    ? !checkValidationMail(contact?.email, index) : false}
                  required
                />
              </Form.Group>
            </Col>
            <Col md="5">
              <Form.Group className="">
                <Form.Label
                  htmlFor="new-connection-phone"
                >
                  {`${t('Enterprise.phoneNumber')} (${t('Form.optional')})`}
                </Form.Label>
                <PhoneInput
                  country="fr"
                  placeholder="+33 1 02 03 04 05"
                  inputClass="phone-number-input font-style-regular"
                  buttonClass="phone-number-button"
                  dropdownClass="font-style-light"
                  onChange={(value) => updateContact(contact?.id, value, 'phone_number')}
                  value={contact.phone_number}
                  isValid={checkValidationPhoneNumber(contact?.phone_number)}
                />
              </Form.Group>
            </Col>
            <Col md="2" className="d-flex justify-content-center align-items-end">
              <AWButton
                backgroundColor="transparent"
                onClick={() => (!index ? addContact() : deleteContact(contact?.id))}
                type="button"
              >
                <img
                  src={!index ? add : trash}
                  alt={!index ? 'add' : 'trash'}
                  className="opacity-50"
                  height="25px"
                  width="25px"
                />
              </AWButton>
            </Col>
          </Row>
        ))}
      </Row>
      <Row className="justify-content-end mt-4">
        <Col xl="4">
          <AWButton
            disabled={isSubmitDisabled}
            type="submit"
          >
            {
              t('InviteRecipientForm.sendInvitations', 'Envoyer les invitations')
            }
          </AWButton>
        </Col>
      </Row>
    </Form>
  );
};

export default InviteRecipientForm;
