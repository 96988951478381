import { ApprovalDetails } from '@root/interfaces/approval.interface';
import { getProviderApprovals, getApprovalById } from '@root/services/approval.service';
import { SessionExpired } from '@root/helpers/catalog.error';

// eslint-disable-next-line import/prefer-default-export
export const initApprovals = async (enterpriseId: string) => {
  const res: {
    approvals: any[];
    isSessionExpired?: boolean;
  } = { approvals: [] };

  const approvalsRes = await getProviderApprovals(enterpriseId);
  if (approvalsRes.success) {
    approvalsRes.data.map((approval) => res.approvals.push({
      id: approval.id,
      sponsor: {
        name: approval.sponsor_name,
        legalForm: approval.sponsor_legal_form,
        identification_number: approval.sponsor_identification_number,
        email: approval.sponsor_email,
      },
      customer: {
        customer_name: approval.customer_name,
        customer_identification_number: approval.customer_identification_number,
      },
      mission: { label: approval.mission_label, description: approval.mission_description },
      workfield: { label: approval.workfield_label, description: approval.workfield_description },
      status: approval.status,
      sponsorContact: {
        first_name: '',
        last_name: '',
        email: '',
      },
      customerContact: {
        first_name: '',
        last_name: '',
        email: '',
      },
      businessAmount: approval.amount || '-',
      emissionDate: approval.valid_from || new Date(2000, 0, 1),
    }));
  } else if (
    !Array.isArray(approvalsRes.data)
    && (approvalsRes.hasError(SessionExpired))
  ) {
    res.isSessionExpired = true;
  }
  return res;
};

export const initApprovalById = async (approvalId: string) => {
  const approvalRes = await getApprovalById(approvalId);
  const res: {
    approval: ApprovalDetails;
    isSessionExpired?: boolean;
  } = {
    approval: {
      id: approvalRes?.data?.id,
      mission_description: approvalRes?.data?.mission_description,
      mission_label: approvalRes?.data?.mission_label,
      provider_identification_number: approvalRes?.data?.provider_identification_number,
      provider_legal_form: approvalRes?.data?.provider_legal_form,
      provider_name: approvalRes?.data?.provider_name,
      sponsor_identification_number: approvalRes?.data?.sponsor_identification_number,
      sponsor_legal_form: approvalRes?.data?.sponsor_legal_form,
      sponsor_name: approvalRes?.data?.sponsor_name,
      sponsor_email: approvalRes?.data?.sponsor_email,
      status: approvalRes?.data?.status,
      workfield_description: approvalRes?.data?.workfield_description,
      workfield_label: approvalRes?.data?.workfield_label,
      document_id: approvalRes?.data?.document_id,
      file_id: approvalRes?.data?.file_id,
      type_id: approvalRes?.data?.type_id,
      mime_type: approvalRes?.data?.mime_type,
      yousign_signature_request_id: approvalRes?.data?.yousign_signature_request_id,
      customer_user: approvalRes?.data?.customer_user,
      customer_email: approvalRes?.data?.customer_email,
    },
  };

  if (approvalRes.success) {
    res.approval = (approvalRes.data);
  } else if (approvalRes.hasError(SessionExpired)) {
    res.isSessionExpired = true;
  }
  return res;
};
