import React, {
  useContext, useEffect, useState,
} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { UserContext } from '@root/contexts/user.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { AlertContext } from '@root/contexts/alert.context';
import {
  filterByContact,
  filterByIdentity,
  filterByKeyword,
  filterByStatus,
} from '@customer/services/approval.service';
import AWContainer from '@root/components/AWContainer/AWContainer';
import CardApproval from '@customer/components/CardApproval/CardApproval';
import ApprovalsFilters from '@customer/views/Approvals/ApprovalsFilters/ApprovalsFilters';
import { Approval, ApprovalFilter } from '@root/interfaces/approval.interface';
import Tabs from '@customer/components/Tabs/Tabs';
import { initApprovals as initApprovalsBuilder } from '@customer/views/Approvals/approvals.builder';
import i18n from '@root/locales/i18n';
import BurgerMenu from '@root/components/BurgerMenu/BurgerMenu';
import { BurgerMenu as IBurgerMenu } from '@root/interfaces/menu.interface';
import { CUSTOMER_BASE_PATH } from '@root/helpers/constants.helper';
import { useHistory } from 'react-router-dom';

const tabs = [
  {
    label: i18n.t('ApprovalsFilters.tabAll', 'Toutes'),
    key: 'all',
  },
  {
    label: i18n.t('ApprovalsFilters.tabProvider', 'En attente du ST'),
    key: 'provider',
  },
  {
    label: i18n.t('ApprovalsFilters.tabCustomer', 'En attente du client'),
    key: 'customer',
  },
  {
    label: i18n.t('ApprovalsFilters.tabSponsor', 'En attente du MOA'),
    key: 'sponsor',
  },
];

const burgerMenu: IBurgerMenu = {
  sections: [
    {
      options: [
        {
          label: `+ ${i18n.t('ApprovalBurgerMenu.newApproval', "Nouvelle demande d'agrément")}`,
          linkTo: `${CUSTOMER_BASE_PATH}/approvals/new/mission`,
        },
        // { label: i18n.t('ApprovalBurgerMenu.handlingApproval', 'Gestion des demandes') },
        // { label: i18n.t('ApprovalBurgerMenu.reminders', 'Relances') },
      ],
    },
    // {
    //   options: [
    //     { label: i18n.t('ApprovalBurgerMenu.models', 'Modèles') },
    //     { label: i18n.t('ApprovalBurgerMenu.users', 'Utilisateurs') },
    //     { label: i18n.t('ApprovalBurgerMenu.configuration', 'Configuration') },
    //   ],
    // },
  ],
};

const Approvals = () => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const history = useHistory();

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [approvalList, setApprovalList] = useState<Approval[]>([]);
  const [approvalsToDisplay, setApprovalsToDisplay] = useState<Approval[]>([]);
  const [startFiltersSearch, setStartFiltersSearch] = useState<boolean>(false);
  const [approvalFilters, setApprovalFilters] = useState<ApprovalFilter>({});
  const [loadingBtn, setLoadingBtn] = useState<string>();
  const { setNotif } = useContext(AlertContext);

  const { waitWithLoad, isLoading } = useLoadingPromise();
  const isBtnLoading = (btn: string) => isLoading && loadingBtn === btn;

  const initApprovals = async () => {
    if (user.currentEnterprise?.id) {
      const { approvals, isSessionExpired } = await waitWithLoad(
        initApprovalsBuilder(user.currentEnterprise.id),
      );
      setApprovalList(approvals);
      setApprovalsToDisplay(approvals);
      if (isSessionExpired) setNotif(t('Punchout.expired', 'Votre session est expirée'));
    }
  };

  useEffect(() => {
    initApprovals();
  }, [user.currentEnterprise?.id]);

  const handleFiltersChange = (fieldName: keyof ApprovalFilter) => (e) => {
    setApprovalFilters({ ...approvalFilters, [fieldName]: e.target?.value || e.value });
  };

  const handleFiltersSearch = () => {
    setApprovalsToDisplay(approvalList);
    setStartFiltersSearch((prevValue) => !prevValue);
  };

  const applyFilters = () => {
    let filteredApprovals: Approval[] = approvalList;

    if (approvalFilters.identity) {
      filteredApprovals = filterByIdentity(approvalFilters.identity!, filteredApprovals);
    }

    if (approvalFilters.contact) {
      filteredApprovals = filterByContact(approvalFilters.contact!, filteredApprovals);
    }

    if (approvalFilters.keyword) {
      filteredApprovals = filterByKeyword(approvalFilters.keyword!, filteredApprovals);
    }

    if (approvalFilters.status) {
      filteredApprovals = filterByStatus(approvalFilters.status, filteredApprovals);
    }

    setApprovalsToDisplay(filteredApprovals);
  };

  useEffect(() => {
    applyFilters();
  }, [startFiltersSearch]);

  const applyTabFilters = () => {
    let filteredApprovals: Approval[] = approvalList;

    switch (activeTab.key) {
      case 'provider':
        filteredApprovals = filterByStatus('providerSignatureMissing', filteredApprovals);
        break;
      case 'customer':
        filteredApprovals = filterByStatus('customerSignatureMissing', filteredApprovals);
        break;
      case 'sponsor':
        filteredApprovals = filterByStatus('sponsorSignatureMissing', filteredApprovals);
        break;
      default:
        filteredApprovals = approvalList;
        break;
    }
    setApprovalsToDisplay(filteredApprovals);
  };

  useEffect(() => {
    applyTabFilters();
  }, [activeTab]);

  return (
    <AWContainer className="approvals" isLoading={isLoading}>
      <AWContainer.Main
        title={t('Approvals.title', "Demandes d'agrément")}
        titleSideCounter={approvalsToDisplay.length.toString()}
      >
        <Breadcrumb>
          <BreadcrumbItem>{t('Approvals.myActivity', 'Mon activité')}</BreadcrumbItem>
          <BreadcrumbItem active>{t('Approvals.approvals', "Demandes d'agrément")}</BreadcrumbItem>
        </Breadcrumb>
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        {approvalsToDisplay.map((approval) => (
          <CardApproval key={approval.id} approval={approval} onClick={() => history.push(`/customer/approvals/${approval.id}`)} />
        ))}
      </AWContainer.Main>
      <AWContainer.Side
        title={t('ApprovalsFilters.filters', 'Filtrer')}
        titleSide={<BurgerMenu sections={burgerMenu.sections} />}
      >
        <ApprovalsFilters
          approvalFilters={approvalFilters}
          setApprovalFilters={setApprovalFilters}
          handleFiltersSearch={handleFiltersSearch}
          onChange={handleFiltersChange}
          isBtnLoading={isBtnLoading}
          handleLoadingBtn={setLoadingBtn}
        />
      </AWContainer.Side>
    </AWContainer>
  );
};

export default Approvals;
