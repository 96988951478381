import {
  getEnterpriseMembers as getEnterpriseMembersService,
  getAllDepartments as getAllDepartmentsService,
} from '@provider/services/enterprise.service';

import {
  getEnterpriseAddressesConfig,
  getEnterpriseConfig,
  getEnterpriseLegalFormConfig,
  getEnterprisePhoneNumbersConfig,
  getEnterpriseRepresentativesConfig,
  getEnterprisesConfig,
} from '@root/api-configs/enterprise.api.config';

import { getFileConfig } from '@root/api-configs/file.api.confit';
import { CallbackSafeFetch } from '@root/hooks/useSafeFetch';

import {
  Address,
  Enterprise,
  LegalForm,
  PhoneNumber,
  Representative,
} from '@root/interfaces/enterprise.interface';

import { File } from '@root/interfaces/file.interface';
import i18n from '@root/locales/i18n';
import { ApiResponse } from '@root/helpers/response-handler';
import {
  BE_IDENTIFICATION_NUMBER_REGEX,
  DE_IDENTIFICATION_NUMBER_REGEX,
  FR_IDENTIFICATION_NUMBER_REGEX,
} from '@root/helpers/patterns';
import { AddWorkingCountry, CountryCode } from '@root/interfaces/utils.interface';

export const getEnterpriseMembers = async (identification_number: string) => {
  const membersRes = await getEnterpriseMembersService(
    identification_number,
    {
      fields: 'identification_number,user_id,is_admin',
    },
  );
  if (membersRes.success && membersRes.data.some((m) => Object.hasOwn(m, 'user_id'))) {
    return membersRes.data;
  }
  return [];
};

export const getAllDepartments = async () => {
  const res = await getAllDepartmentsService({
    fields: ['insee_code', 'display_name', 'prefecture', 'id'],
    search: { country: 'fr' },
    page_size: 102,
    sort: 'insee_code',
  });
  if (res.success && res.data?.length) {
    return res.data;
  }
  return [];
};

// Services depending on 'useSafeFetchCallback'

export const getLogoFromEnterpriseId = async (
  callApi: CallbackSafeFetch,
  id: string,
): Promise<string> => {
  try {
    const customerRes: ApiResponse<Enterprise[]> = await callApi(
      getEnterprisesConfig,
      {},
      {
        fields: 'logo_id',
        search: { id },
      },
    );
    if (customerRes.success && customerRes.data?.length) {
      const fileId = customerRes.data[0].logo_id;
      if (fileId) {
        const fileRes: ApiResponse<File> = await callApi(getFileConfig, { fileId });
        if (fileRes.success) {
          return fileRes.data.signedUrl || '';
        }
      }
    }
  } catch {
    return '';
  }
  return '';
};

const fetchRepresentatives = async (
  callbackApi: CallbackSafeFetch,
  enterpriseId?: string,
): Promise<Representative[]> => {
  if (!enterpriseId) return [];
  const representativesRes: ApiResponse<Representative[]> = await callbackApi(
    getEnterpriseRepresentativesConfig,
    { enterpriseId },
  );
  return representativesRes.success ? representativesRes.data : [];
};

const fetchLegalForm = async (
  callbackApi: CallbackSafeFetch,
  legalFormId: string,
) => {
  const legalFormRes: ApiResponse<LegalForm> = await callbackApi(
    getEnterpriseLegalFormConfig,
    { legalFormId },
    {
      fields: 'name,display_name',
      page_size: 100,
    },
  );
  if (legalFormRes.success) {
    return legalFormRes.data;
  }
  return null;
};

const fetchPhoneNumbers = async (
  callbackApi: CallbackSafeFetch,
  identificationNumber: string,
): Promise<PhoneNumber[]> => {
  const phoneResponse: ApiResponse<PhoneNumber[]> = await callbackApi(
    getEnterprisePhoneNumbersConfig,
    { identificationNumber },
    { fields: 'number' },
  );
  if (phoneResponse.success) {
    return phoneResponse.data;
  }
  return [];
};

const fetchAddresses = async (
  callbackApi: CallbackSafeFetch,
  identificationNumber: string,
): Promise<Address[]> => {
  const addressResponse: ApiResponse<Address[]> = await callbackApi(
    getEnterpriseAddressesConfig,
    { identificationNumber },
    { fields: 'address,additionnal_address,zipcode,town,country' },
  );
  if (addressResponse.success) {
    return addressResponse.data;
  }
  return [];
};

export const fetchEnterprise = async (
  callbackApi: CallbackSafeFetch,
  enterpriseId: string,
): Promise<Enterprise> => {
  const enterprise: Enterprise = {};
  const enterpriseDetailsRes = await callbackApi(
    getEnterpriseConfig,
    { identifier: enterpriseId },
    {
      fields: [
        'id',
        'name',
        'legal_form_id',
        'registration_town',
        'main_activity_code',
        'contractualization_language',
        'tax_identification_number',
        'identification_number',
        'country',
      ],
    },
  );
  if (enterpriseDetailsRes.success && enterpriseDetailsRes.data) {
    const identificationNumber = enterpriseDetailsRes.data?.identification_number;
    enterprise.id = enterpriseDetailsRes.data?.id;
    enterprise.name = enterpriseDetailsRes.data?.name;
    enterprise.contractualization_language = enterpriseDetailsRes.data?.contractualization_language;
    enterprise.registration_town = enterpriseDetailsRes.data?.registration_town;
    enterprise.main_activity_code = enterpriseDetailsRes.data?.main_activity_code;
    enterprise.tax_identification_number = enterpriseDetailsRes.data?.tax_identification_number;
    enterprise.country = enterpriseDetailsRes.data?.country;
    enterprise.identification_number = identificationNumber;
    let phoneNumbers: PhoneNumber[];
    let legalForm: LegalForm | null;
    let addresses: Address[];
    [
      phoneNumbers,
      legalForm,
      addresses,
      enterprise.representatives,
    ] = await Promise.all([
      fetchPhoneNumbers(callbackApi, identificationNumber),
      fetchLegalForm(callbackApi, enterpriseDetailsRes.data?.legal_form_id),
      fetchAddresses(callbackApi, identificationNumber),
      fetchRepresentatives(callbackApi, enterpriseDetailsRes.data?.id),
    ]);
    return {
      ...enterprise,
      legal_form: legalForm?.display_name || '',
      phone_numbers: phoneNumbers.map((p) => p.number),
      address: addresses[0],
    };
  }
  return enterprise;
};

export const enterpriseTabsView = [
  {
    label: i18n.t('Enterprise.generalInfo', 'Informations générales'),
    key: 'info',
  },
  {
    label: i18n.t('Enterprise.contact', 'Contact'),
    key: 'contact',
  },
];

export const checkIdentificationNumberFormat = (
  identificationNumber?: string,
  country: AddWorkingCountry = 'fr',
) => {
  if (!identificationNumber) return false;
  switch (country) {
    case CountryCode.De:
      return !!identificationNumber.match(DE_IDENTIFICATION_NUMBER_REGEX);
    case CountryCode.Be:
      return !!identificationNumber.match(BE_IDENTIFICATION_NUMBER_REGEX);
    default:
      return !!identificationNumber.match(FR_IDENTIFICATION_NUMBER_REGEX);
  }
};

export const getIdentificationLabel = (country?: CountryCode) => {
  if (country === CountryCode.Be) {
    return i18n.t('Enterprise.identificationNumberBe', 'Numéro BCE');
  }
  if (country === CountryCode.De) {
    return i18n.t('Enterprise.identificationNumberDe', "Numéro d'enregistrement");
  }
  return i18n.t('Enterprise.identificationNumberFr', 'SIRET');
};
