import React from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import ProtectedRoute from '@root/routes/ProtectedRoute';
import Contracts from '@root/views/Contracts/Contracts';

const ContractRoutes = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoute path={`${path}`} exact>
        <Contracts />
      </ProtectedRoute>
    </Switch>
  );
};

export default ContractRoutes;
