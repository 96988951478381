import React, {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import { initApprovalById as initApprovalsBuilder } from '@provider/views/Approvals/approvals.provider.builder';
import Tabs from '@customer/components/Tabs/Tabs';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { AlertContext } from '@root/contexts/alert.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { useParams } from 'react-router-dom';

import { ApprovalProviderContext } from '@provider/contexts/approvalProvider.context';
import { getFile } from '@root/services/file.service';
import { mimeType } from '@root/helpers/utils';
import { getApprovalDocumentModelByS3Id } from '@root/services/approvalDocument.service';
import { TabMenu } from '@root/interfaces/menu.interface';
import ApprovalInformations from '@provider/views/Approvals/ApprovalContainer/ApprovalInformations/ApprovalInformations';
import ApprovalPreview from '@provider/views/Approvals/ApprovalContainer/ApprovalPreview/ApprovalPreview';
import ApprovalVariables from '@provider/views/Approvals/ApprovalContainer/ApprovalVariables/ApprovalVariables';
import ApprovalSignature from '@provider/views/Approvals/ApprovalContainer/ApprovalSignature/ApprovalSignature';

const ApprovalContainer = () => {
  const { t } = useTranslation();

  const { setNotif } = useContext(AlertContext);
  const { waitWithLoad } = useLoadingPromise();
  const { approvalId } = useParams<{ approvalId: string }>();
  const {
    setPdfFilePath,
    setApprovalToDisplay,
    setModelSelected,
  } = useContext(ApprovalProviderContext);

  const tabs: TabMenu[] = [
    { key: 'infos', label: t('ApprovalsContainer.infos', 'INFOS') },
    {
      key: 'preview',
      label: t('ApprovalsContainer.previsualisation', 'PRÉVISUALISATION'),
    },
    {
      key: 'variables',
      label: t('ApprovalsContainer.variables', 'VARIABLES'),
    },
    {
      key: 'signature',
      label: t('ApprovalsContainer.waitingMySignature', 'EN ATTENTE DE MA SIGNATURE'),
    },
  ];

  const [activeTab, setActiveTab] = useState<TabMenu>(tabs[0]);

  let isLoadingTab = false;
  const initApprovals = async () => {
    if (approvalId && !isLoadingTab) {
      const { approval, isSessionExpired } = await waitWithLoad(
        initApprovalsBuilder(approvalId),
      );
      setApprovalToDisplay(approval);
      if (isSessionExpired) setNotif(t('Punchout.expired', 'Votre session est expirée'));
      isLoadingTab = true;
      if (approval.document_id) {
        if (approval.mime_type === mimeType.html) {
          const fileRes = await getApprovalDocumentModelByS3Id(approval.document_id);
          if (fileRes.success) {
            setModelSelected(fileRes.data);
          }
        }
        if (approval.mime_type === mimeType.pdf) {
          const fileRes = await getFile(approval.document_id);
          if (fileRes.success) {
            setPdfFilePath(fileRes.data.signedUrl);
          }
        }
      }
    }
  };

  useEffect(() => {
    initApprovals();
  }, []);

  const mainContent = () => {
    switch (activeTab.key) {
      case 'infos':
        return <ApprovalInformations />;

      case 'preview':
        return <ApprovalPreview />;

      case 'variables':
        return <ApprovalVariables />;

      default:
        return <ApprovalSignature />;
    }
  };

  return (
    <AWContainer className="approvals">
      <AWContainer.Main
        title={t('ApprovalsContainer.title', "Demande d'agrément")}
      >
        <Breadcrumb>
          <BreadcrumbItem>{t('Approvals.myActivity', 'Mon activité')}</BreadcrumbItem>
          <BreadcrumbItem href="/provider/approvals">{t('Approvals.approvals', "Demandes d'agrément")}</BreadcrumbItem>
          <BreadcrumbItem active>{t('Approvals.details', 'Détails')}</BreadcrumbItem>
        </Breadcrumb>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        {mainContent()}
      </AWContainer.Main>
      <AWContainer.Side />
    </AWContainer>
  );
};

export default ApprovalContainer;
