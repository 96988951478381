import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import AWShortCard from '@root/components/Cards/AWShortCard/AWShortCard';
import LabelValueList from '@root/components/LabelValueList/LabelValueList';
import { UserContext } from '@root/contexts/user.context';
import { ApprovalContext } from '@customer/contexts/approval.context';
import { getMissionsForApprovals } from '@customer/services/mission.service';
import { ILabelValueList } from '@root/interfaces/labelValueList.interface';
import { MissionApproval } from '@customer/interfaces/mission.interface';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { formatDate, formatSiret } from '@root/helpers/utils';
import { useTranslation } from 'react-i18next';
import AWContainer from '@root/components/AWContainer/AWContainer';

const MissionStep = () => {
  const { t } = useTranslation();
  const {
    activeCardId, setActiveCardId, selectedMission, setSelectedMission,
  } = useContext(ApprovalContext);
  const { user } = useContext(UserContext);
  const { waitWithLoad, isLoading } = useLoadingPromise();

  const [missionList, setMissionList] = useState<MissionApproval[]>([]);
  const [labelValue, setLabelValue] = useState<ILabelValueList[]>([
    {
      title: t('NewApprovalSteps.companyInvolved', 'Entreprise concernée'),
      content: [''],
      subContent: [''],
    },
    {
      title: t('Approvals.providerLong', 'Sous-traitant'),
      content: [''],
      subContent: [''],
    },
    {
      title: t('NewApprovalSteps.missionSpan', 'Période de la mission'),
      content: [''],
      subContent: [''],
    },
    {
      title: t('NewApprovalSteps.workfieldAddress', 'Adresse du chantier'),
      content: [''],
    },
    {
      title: t('NewApprovalSteps.description', 'Description'),
      content: [''],
    },
  ]);

  const displaySiretIfExists = (siret?: string) => (siret ? formatSiret(siret) : '');

  const displayMissionDates = (starts_at: Date, ends_at?: Date) => {
    let messageToDisplay = `${t('NewApprovalSteps.description', 'Description')} :`;
    messageToDisplay += ends_at
      ? ` ${t('Common.from', 'du')} ${formatDate(starts_at)} ${t('Common.to', 'au')} ${formatDate(
        ends_at,
      )}`
      : ` ${t('Common.startAt', 'à partir du')} ${formatDate(starts_at)}`;
    return messageToDisplay;
  };

  const displayMarketTypeIfExists = (marketType?: string) => (marketType ? `| ${marketType}` : '');

  const setLabelValueDatas = async (id: string) => {
    const focusedMission = selectedMission.id === id
      ? selectedMission
      : await missionList.find((mission) => mission.id === id);
    if (focusedMission) {
      const labelValueDatas: ILabelValueList[] = [
        {
          title: t('NewApprovalSteps.companyInvolved', 'Entreprise concernée'),
          content: [focusedMission.customer_name],
          subContent: [displaySiretIfExists(focusedMission.customer_identification_number)],
        },
        {
          title: t('Approvals.providerLong', 'Sous-traitant'),
          content: [`${focusedMission.provider_legal_form || ''} ${focusedMission.provider_name}`],
          subContent: [displaySiretIfExists(focusedMission.provider_identification_number)],
        },
        {
          title: t('NewApprovalSteps.missionSpan', 'Période de la mission'),
          content: [formatDate(focusedMission.starts_at)],
          subContent: [formatDate(focusedMission.ends_at) || ''],
        },
        {
          title: t('NewApprovalSteps.workfieldAddress', 'Adresse du chantier'),
          content: [focusedMission.workfield_address || ''],
        },
        {
          title: t('NewApprovalSteps.description', 'Description'),
          content: [focusedMission.description || ''],
        },
      ];
      if (focusedMission !== selectedMission) setSelectedMission(focusedMission);
      setLabelValue(labelValueDatas);
    }
  };

  const getMissions = async (enterpriseId: string) => {
    const missions = await waitWithLoad(getMissionsForApprovals(enterpriseId));
    setMissionList(missions.data);
  };

  useEffect(() => {
    if (user.currentEnterprise?.id) {
      getMissions(user.currentEnterprise.id);
    }
  }, [user.currentEnterprise?.id]);

  useEffect(() => {
    if (activeCardId) {
      setLabelValueDatas(activeCardId);
    }
  }, [activeCardId]);

  return (
    <AWContainer isLoading={isLoading}>
      <Row className="mission-step mt-4">
        <Col xs="6" className="mission-step__cards">
          <div>
            <div>{`${t('NewApprovalSteps.chooseMission', 'Choix de la mission')} :`}</div>
            {missionList
              ? missionList.map((mission) => (
                <AWShortCard
                  key={mission.id}
                  id={mission.id}
                  title={mission.label}
                  subTitle={`${mission.workfield_name || '-'} ${displayMarketTypeIfExists(
                    mission.market_type,
                  )}`}
                  content={displayMissionDates(mission.starts_at, mission.ends_at)}
                  subContent={`${t('Approvals.providerLong', 'Sous-traitant')} :`}
                  activeCardId={activeCardId}
                  setActiveCardId={setActiveCardId}
                  company={{
                    legalForm: mission.provider_legal_form ?? '',
                    companyName: mission.provider_name,
                  }}
                />
              ))
              : null}
          </div>
        </Col>
        <Col xs="6" className="mission-step__label-value-list">
          <LabelValueList labelValues={labelValue} />
        </Col>
      </Row>
    </AWContainer>
  );
};

export default MissionStep;
