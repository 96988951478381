import { ApprovalContext } from '@customer/contexts/approval.context';
import LabelValueList from '@root/components/LabelValueList/LabelValueList';
import { ILabelValueList } from '@root/interfaces/labelValueList.interface';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { convertModelDocumentFromStringToImg } from '@customer/views/Approvals/NewApproval/NewApprovalSteps/utils/convertHtmlToImg.service';
import { useTranslation } from 'react-i18next';

const ValidationStep = () => {
  const { t } = useTranslation();
  const {
    selectedMission, modelSelected, pdfFile, uploadedPdfUrl, modelVariableDictionary, sponsorEmail,
  } = useContext(ApprovalContext);

  let document = modelSelected.value;
  const regex = /\{\{(.*?)\}\}/gm;
  const documentVariables = document.match(regex);
  documentVariables?.forEach((el) => {
    document = modelVariableDictionary
      && document.replace(el, modelVariableDictionary[el.replace(/\{{|}}|\d+./g, '')]?.value);
  });

  const [modal, setModal] = useState<{
    isOpen: boolean;
    title?: string;
  }>({ isOpen: false });

  // modal handling functions
  const closeModal = () => setModal({ isOpen: false });

  const handleClickOnPreview = async () => {
    await setModal({
      isOpen: true,
      title: modelSelected.label,
    });
    convertModelDocumentFromStringToImg(
      document,
      'id-validation-step__temp-modal-preview',
      'id-validation-step__modal-preview',
    );
  };

  useEffect(() => {
    if (modelSelected.value) {
      convertModelDocumentFromStringToImg(
        modelSelected.value,
        'id-validation-step__temp-preview',
        'id-validation-step__preview',
      );
    }
  }, []);

  const labelValues: ILabelValueList[] = [
    {
      title: t('Approvals.workfield', 'Chantier'),
      content: [selectedMission.workfield_name!],
    },
    {
      title: t('Approvals.mission', 'Mission'),
      content: [selectedMission.label],
    },
    {
      title: t('Approvals.providerLong', 'Sous-traitant'),
      content: [`${selectedMission.provider_legal_form} ${selectedMission.provider_name}`],
    },
    {
      title: t('Approvals.sponsorLong', "Maître d'ouvrage"),
      content: [`${sponsorEmail}`],
    },
  ];

  return (
    <>
      <h2 className="my-4">Récapitulatif</h2>
      <Col xs="12" className="mx-2 my-4">
        <Row>
          <Col xs="6" className="validation-step__preview">
            {modelSelected.value ? (
              <>
                <div
                  id="id-validation-step__preview"
                  className="d-flex justify-content-center"
                  onClick={() => handleClickOnPreview()}
                />
                <div id="id-validation-step__temp-preview" />
              </>
            ) : (
              ''
            )}
            {pdfFile.size ? (
              <iframe
                src={uploadedPdfUrl}
                width="100%"
                height="500px"
                title="uploaded pdf last preview"
              />
            ) : (
              ''
            )}
          </Col>
          <div className="validation-step__label-value-list">
            <LabelValueList labelValues={labelValues} />
          </div>
        </Row>
      </Col>
      <h2 className="my-4">Prochaines étapes</h2>
      <ul className="my-4">
        <li>
          {t(
            'ApprovalValidationStep.sendingToSponsor',
            "Envoyer votre demande d'agrément au sous-traitant pour qu'il puisse vérifier les données renseignées (et renseigner ses variables le cas échéant)",
          )}
        </li>
        <li>{t('ApprovalValidationStep.checkInformations', 'Après retour du ST, vérifiez que les informations renseignées vous conviennent')}</li>
        <li>{t('ApprovalValidationStep.sendingToSignature', "Mise en signature de la demande d'agrément")}</li>
        <li>{t('ApprovalValidationStep.signatureProvider', 'Signature du ST')}</li>
        <li>{t('ApprovalValidationStep.signatureClient', 'Signature du client (vous)')}</li>
        <li>{t('ApprovalValidationStep.signatureSponsor', "Signature du maître d'ouvrage")}</li>
      </ul>
      <Modal
        show={modal.isOpen}
        onHide={closeModal}
        dialogClassName="validation-step__modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modal.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div
              id="id-validation-step__modal-preview"
              className="validation-step__modal-preview"
            />
            <div id="id-validation-step__temp-modal-preview" />
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ValidationStep;
