import i18n from '@root/locales/i18n';
import { ILabelValueCard } from '@root/interfaces/labelValueCard.interface';
import { ApprovalDetails, ApprovalStatusEnum } from '@root/interfaces/approval.interface';

export const signatureStatus = {
  allSignaturesMissing: i18n.t('CardApproval.allSignaturesMissing', 'aucune signature'),
  providerValidationMissing: i18n.t(
    'CardApproval.providerValidationMissing',
    'en attente de validation ST',
  ),
  customerValidationMissing: i18n.t(
    'CardApproval.customerValidationMissing',
    'en attente de validation client',
  ),
  providerSignatureMissing: i18n.t(
    'CardApproval.providerSignatureMissing',
    'en attente de signature ST',
  ),
  customerSignatureMissing: i18n.t(
    'CardApproval.customerSignatureMissing',
    'en attente de signature client',
  ),
  sponsorSignatureMissing: i18n.t(
    'CardApproval.sponsorSignatureMissing',
    'en attente de signature MOA',
  ),
  signed: i18n.t('CardApproval.signed', 'signée'),
};

export const setSignatureStatus = (approval: ApprovalDetails) => {
  if (
    approval.status === ApprovalStatusEnum.DRAFT
    || approval.status === ApprovalStatusEnum.READY
  ) {
    return signatureStatus.allSignaturesMissing;
  }
  if (approval.status === ApprovalStatusEnum.WAITING_PROVIDER_VALIDATION) {
    return signatureStatus.providerValidationMissing;
  }
  if (approval.status === ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION) {
    return signatureStatus.customerValidationMissing;
  }
  if (approval.status === ApprovalStatusEnum.WAITING_FOR_PROVIDER) {
    return signatureStatus.providerSignatureMissing;
  }
  if (approval.status === ApprovalStatusEnum.WAITING_FOR_CUSTOMER) {
    return signatureStatus.customerSignatureMissing;
  }
  if (approval.status === ApprovalStatusEnum.WAITING_FOR_SPONSOR) {
    return signatureStatus.sponsorSignatureMissing;
  }
  if (approval.status === ApprovalStatusEnum.ACCEPTED) {
    return signatureStatus.signed;
  }
  return '-';
};

export const setApprovalInformationsLabelValueDatas = (
  approval: ApprovalDetails,
): ILabelValueCard[] => [
  {
    title: i18n.t('Approvals.sponsor', 'MOA'),
    content:
        `${approval?.sponsor_email ? approval?.sponsor_email : '-'}`
        || `${approval?.sponsor_legal_form ? approval?.sponsor_legal_form : ''} ${
          approval?.sponsor_name ? approval?.sponsor_name : '-'
        }`,
  },
  {
    title: i18n.t('Approvals.providerLong', 'Sous-traitant'),
    content: `${approval?.provider_legal_form ? approval?.provider_legal_form : ''} ${
      approval?.provider_name ? approval?.provider_name : '-'
    }`,
  },
  {
    title: i18n.t('Approvals.workfield', 'Chantier'),
    content: approval?.workfield_label ? approval?.workfield_label : '-',
  },
  {
    title: i18n.t('Approvals.description', 'Description'),
    content: approval?.workfield_description ? approval?.workfield_description : '-',
  },
  {
    title: i18n.t('Approvals.mission', 'Mission'),
    content: approval?.mission_label ? approval?.mission_label : '-',
  },
  {
    title: i18n.t('Approvals.status', 'Statut'),
    content: setSignatureStatus(approval),
  },
];
