import React, {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, BreadcrumbItem } from 'react-bootstrap';
import AWContainer from '@root/components/AWContainer/AWContainer';
import { AlertContext } from '@root/contexts/alert.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { useLocation, useParams } from 'react-router-dom';
import { initApprovalById as initApprovalsBuilder } from '@customer/views/Approvals/approvals.builder';
import { ApprovalContext } from '@customer/contexts/approval.context';
import Tabs from '@customer/components/Tabs/Tabs';
import { TabMenu } from '@root/interfaces/menu.interface';
import { getFile } from '@root/services/file.service';
import { mimeType } from '@root/helpers/utils';
import { getApprovalDocumentModelByS3Id } from '@root/services/approvalDocument.service';
import ApprovalSideContainer from '@customer/views/Approvals/ApprovalSideContainer/ApprovalSideContainer';
import ApprovalInformations from '@customer/views/Approvals/ApprovalContainer/ApprovalInformations/ApprovalInformations';
import ApprovalPreview from '@customer/views/Approvals/ApprovalContainer/ApprovalPreview/ApprovalPreview';
import ApprovalSignature from '@customer/views/Approvals/ApprovalContainer/ApprovalSignature/ApprovalSignature';

const ApprovalContainer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { setNotif } = useContext(AlertContext);
  const { waitWithLoad } = useLoadingPromise();
  const { approvalId } = useParams<{ approvalId: string }>();
  const { setApprovalToDisplay, setPdfFilePath, setModelData } = useContext(ApprovalContext);

  const tabs: TabMenu[] = [
    { key: 'infos', label: t('ApprovalsContainer.infos', 'INFOS') },
    {
      key: 'preview',
      label: t('ApprovalsContainer.previsualisation', 'PRÉVISUALISATION'),
    },
    {
      key: 'signature',
      label: t('ApprovalsContainer.tabSignatures', 'SIGNATURE'),
    },
  ];
  const [activeTab, setActiveTab] = useState<TabMenu>(tabs[0]);

  let isLoadingTab = false;
  const initApprovals = async () => {
    if (approvalId && !isLoadingTab) {
      const { approval, isSessionExpired } = await waitWithLoad(
        initApprovalsBuilder(approvalId),
      );
      setApprovalToDisplay(approval);
      if (isSessionExpired) setNotif(t('Punchout.expired', 'Votre session est expirée'));
      isLoadingTab = true;
      if (approval.document_id) {
        if (approval.mime_type === mimeType.html) {
          const fileRes = await getApprovalDocumentModelByS3Id(approval.document_id);
          if (fileRes.success) {
            setModelData(fileRes.data);
          }
        }
        if (approval.mime_type === mimeType.pdf) {
          const fileRes = await getFile(approval.document_id);
          if (fileRes.success) {
            setPdfFilePath(fileRes.data.signedUrl);
          }
        }
      }
    }
  };

  useEffect(() => {
    initApprovals();
  }, []);

  const mainContent = () => {
    switch (activeTab.key) {
      case 'infos':
        return <ApprovalInformations />;

      case 'preview':
        return <ApprovalPreview />;

      default:
        return <ApprovalSignature />;
    }
  };

  return (
    <AWContainer className="approvals">
      <AWContainer.Main
        title={t('ApprovalsContainer.title', "Demande d'agrément")}
      >
        <Breadcrumb>
          <BreadcrumbItem>{t('Approvals.myActivity', 'Mon activité')}</BreadcrumbItem>
          <BreadcrumbItem href="/customer/approvals">{t('Approvals.approvals', "Demandes d'agrément")}</BreadcrumbItem>
          <BreadcrumbItem active>{t('Approvals.details', 'Détails')}</BreadcrumbItem>
        </Breadcrumb>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        {mainContent()}
      </AWContainer.Main>
      <AWContainer.Side>
        {
          pathname.match('/signataires')
            ? <ApprovalSideContainer />
            : ''
        }
      </AWContainer.Side>
    </AWContainer>
  );
};

export default ApprovalContainer;
