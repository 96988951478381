import React, {
  createContext, useState, useMemo, ReactNode,
} from 'react';
import { ApprovalDetails } from '@root/interfaces/approval.interface';
import { Option } from '@root/interfaces/utils.interface';

interface IApprovalProviderContext {
  approvalToDisplay: ApprovalDetails;
  setApprovalToDisplay: (approvalToDisplay: ApprovalDetails) => void;
  modelVariableDictionary: Record<string, Option>;
  setModelVariableDictionary: (
    dictionary: Record<string, Option>
  ) => void;
  modelSelected: string;
  setModelSelected: (modelSelected: string) => void;
  pdfFilePath: string;
  setPdfFilePath: (modelSelected: string) => void;
}

export const ApprovalProviderContext = createContext<IApprovalProviderContext>({
  approvalToDisplay: {
    id: '',
    mission_description: '',
    mission_label: '',
    provider_identification_number: '',
    provider_legal_form: '',
    provider_name: '',
    sponsor_identification_number: '',
    sponsor_legal_form: '',
    sponsor_name: '',
    status: '',
    workfield_description: '',
    workfield_label: '',
    document_id: '',
    mime_type: '',
    type_id: '',
    file_id: '',
    yousign_signature_request_id: '',
  },
  setApprovalToDisplay: () => { },
  // used to store provider variables
  modelVariableDictionary: {},
  setModelVariableDictionary: () => { },
  // used to store model document at step 2 "choose model"
  modelSelected: '',
  setModelSelected: () => { },
  // used to store file document at step 2 "choose file"
  pdfFilePath: '',
  setPdfFilePath: () => { },
});

const ApprovalProviderContextProvider = ({ children }: { children: ReactNode }) => {
  const [approvalToDisplay, setApprovalToDisplay] = useState<ApprovalDetails>({
    id: '',
    mission_description: '',
    mission_label: '',
    provider_identification_number: '',
    provider_legal_form: '',
    provider_name: '',
    sponsor_identification_number: '',
    sponsor_legal_form: '',
    sponsor_name: '',
    status: '',
    workfield_description: '',
    workfield_label: '',
    document_id: '',
    mime_type: '',
    type_id: '',
    file_id: '',
    yousign_signature_request_id: '',
  });
  const [modelVariableDictionary, setModelVariableDictionary] = useState({});
  const [modelSelected, setModelSelected] = useState<string>('');
  const [pdfFilePath, setPdfFilePath] = useState<string>('');

  const value = useMemo(
    () => ({
      approvalToDisplay,
      setApprovalToDisplay,
      modelVariableDictionary,
      setModelVariableDictionary,
      modelSelected,
      setModelSelected,
      pdfFilePath,
      setPdfFilePath,
    }),
    [
      approvalToDisplay,
      modelVariableDictionary,
      modelSelected,
      pdfFilePath,
    ],
  );

  return (
    <ApprovalProviderContext.Provider value={value}>
      {children}
    </ApprovalProviderContext.Provider>
  );
};

export default ApprovalProviderContextProvider;
