import React, {
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import {
  Container,
  Row,
  Col,
  Alert,
} from 'react-bootstrap';
import { Route, useHistory, useLocation } from 'react-router-dom/';

// GLOBAL DEPENDENCIES
import { initCurrentUser } from '@root/helpers/user.helper';
import { UserContext } from '@root/contexts/user.context';
import useLoadingPromise from '@root/hooks/useLoadingPromise';
import { SessionUser } from '@root/interfaces/cognito.interface';
import Loading from '@root/components/Loading/Loading';
import useCurrentApp, { Apps } from '@root/hooks/useCurrentApp';
import {
  CUSTOMER_BASE_PATH,
  PROVIDER_BASE_PATH,
  PUNCHOUT,
} from '@root/helpers/constants.helper';
import SideBar from '@root/components/SideBar/SideBar';
import { MenuTabsContext } from '@root/contexts/menuTabs.context';
import AWInvitationModals from '@root/components/AWInvitationModals/AWInvitationModals';
import { goTo } from '@root/helpers/utils';
import { AlertContext } from '@root/contexts/alert.context';
import { currentSession, logOut } from '@root/helpers/session';
import useSafeState from '@root/hooks/useSafeState';

// CUSTOMER DEPENDENCIES
import BackPunchout from '@customer/components/BackPunchout/BackPunchout';
import useRunIntercom from '@root/hooks/useRunIntercom';
import { useSafeFetchCallback } from '@root/hooks/useSafeFetch';
import JobTitleModal from '@root/components/JobTitleModal/JobTitleModal';

interface Props {
  path?: string;
  children: ReactNode;
  exact?: boolean;
  hasSidebar?: boolean;
}

interface LocationState {
  key?: string;
}

const ProtectedRoute = ({
  children,
  path,
  exact,
  hasSidebar,
}: Props) => {
  const [safeChildren, setSafeChildren] = useSafeState<ReactNode>();
  const [isJobModalOpen, setIsJobModalOpen] = useSafeState<boolean>(false);
  const { app, isApp } = useCurrentApp();
  const { user, setUser, setTokenPO } = useContext(UserContext);
  const { notifs } = useContext(AlertContext);
  const { isMenuLoading, autoActiveTab } = useContext(MenuTabsContext);
  const runIntercom = useRunIntercom();
  const initCurrentUserService = useSafeFetchCallback(initCurrentUser);

  const { waitWithLoad, isLoading: isUserLoading } = useLoadingPromise();
  const location = useLocation();
  const { push } = useHistory();

  const state = location.state as LocationState;

  const handleComingToken = (): string | null => {
    let token = sessionStorage.getItem(PUNCHOUT);
    const q = new URLSearchParams(location.search);
    if (!token && q.has('t')) {
      token = q.get('t');
      sessionStorage.setItem(PUNCHOUT, token as string);
    }
    return token;
  };

  const initUser = async (sessionUser: SessionUser) => {
    const userRes = await initCurrentUserService(sessionUser, app);
    if (!userRes.enterprises?.length) {
      if (isApp(Apps.Customer)) {
        goTo(PROVIDER_BASE_PATH);
      } else {
        push(`${PROVIDER_BASE_PATH}/welcome`);
      }
    } else if (
      isApp(Apps.Customer)
      && userRes.enterprises?.every((e) => e.is_vendor && !e.is_customer)
    ) {
      goTo(PROVIDER_BASE_PATH);
    } else if (
      isApp(Apps.Provider)
      && userRes.enterprises?.every((e) => e.is_customer && !e.is_vendor)
    ) {
      goTo(CUSTOMER_BASE_PATH);
    }
    setUser(userRes);
  };

  const checkSession = async () => {
    const token = handleComingToken();
    const session = await currentSession();
    if (!session.isLogged) {
      logOut(true);
    } else {
      if (session.user && !user.id) {
        await waitWithLoad(initUser(session.user));
      }
      if (isApp(Apps.Customer) && token) setTokenPO(token);
      setSafeChildren(children);
    }
  };

  const initPage = async () => {
    autoActiveTab();
    await checkSession();
  };

  const reloadJobModal = async () => {
    if (!user.currentEnterprise?.job_title) {
      const currentUser = await initCurrentUserService(user, app);
      if (currentUser.currentEnterprise && !currentUser.currentEnterprise?.job_title) {
        setIsJobModalOpen(true);
      }
    }
  };

  useEffect(() => {
    if (app) {
      initPage();
    }
  }, [location.pathname, user.currentEnterprise?.id, app, safeChildren]);

  useEffect(() => {
    reloadJobModal();
  }, [user.currentEnterprise?.id]);

  useEffect(() => {
    if (state?.key) {
      setSafeChildren(undefined);
    }
  }, [state?.key]);

  useEffect(() => {
    if (!(isUserLoading || isMenuLoading) && safeChildren && hasSidebar) {
      runIntercom();
    }
  }, [isUserLoading, isMenuLoading, safeChildren, hasSidebar]);

  return (
    <Route path={path} exact={exact}>
      {
        (isUserLoading || isMenuLoading) ? (
          <Loading />
        ) : <> </>
      }
      {
        hasSidebar ? (
          <Container fluid className="p-0">
            <Row className="mx-0">
              <AWInvitationModals />
              <JobTitleModal
                isOpen={isJobModalOpen}
                onClose={() => setIsJobModalOpen(false)}
              />
              <Col xs={2} className="p-0">
                <div className="d-flex justify-content-center align-items-center">
                  <SideBar />
                </div>
              </Col>
              <Col xs={10} className="p-0">
                <div className="d-flex flex-column position-relative">
                  <div className="vh-100 w-100 bg-grey-light overflow-auto">
                    {safeChildren}
                  </div>
                  {isApp(Apps.Customer) ? <BackPunchout /> : ''}
                </div>
              </Col>
            </Row>
          </Container>
        ) : <> </>
      }
      {
        !isUserLoading
          && !isMenuLoading
          && !hasSidebar
          && safeChildren
          ? (
            <div className="vh-100 w-100 m-0">
              <AWInvitationModals />
              <JobTitleModal
                isOpen={isJobModalOpen}
                onClose={() => setIsJobModalOpen(false)}
              />
              {safeChildren}
            </div>
          ) : <> </>
      }
      {
        notifs?.map((notif, i) => (
          <div
            className={`${!notif.show ? 'fade-out' : 'fade-in'} line-break`}
            style={{
              position: 'absolute',
              right: '2em',
              bottom: `${70 * (i + 1)}px`,
            }}
          >
            <Alert
              variant={notif.variant}
            >
              {notif.message}
            </Alert>
          </div>
        ))
      }
    </Route>
  );
};

ProtectedRoute.defaultProps = {
  path: '',
  exact: true,
  hasSidebar: true,
};

export default ProtectedRoute;
