import { ApiResponse, handleResponse, safeFetch } from '@root/helpers/response-handler';
import { File } from '@root/interfaces/file.interface';

const baseUrl = `${process.env.REACT_APP_FILE_API}/v1/file/customer/files`;

// eslint-disable-next-line import/prefer-default-export
export const getFile = async (fileId: string): Promise<ApiResponse<File>> => (
  safeFetch(`${baseUrl}/${fileId}/signed-url`)
);

export const getUploadSignedURL = (
  mime,
  key?,
): Promise<ApiResponse<{ key: string, signedUrl: string }>> => (
  safeFetch(
    `${baseUrl}/upload-signed-url?mime=${mime}&key=${key}`,
  )
);

export const uploadToS3 = async (signedUrl, file): Promise<ApiResponse> => (
  handleResponse(
    fetch(
      signedUrl,
      {
        method: 'PUT',
        body: file,
      },
    ),
  )
);

export const deleteToS3 = (fileId: string) => safeFetch(
  `${baseUrl}/${fileId}`,
  {
    method: 'DELETE',
  },
);
