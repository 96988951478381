import React, {
  createContext,
  ReactNode,
  useState,
  useMemo,
} from 'react';
import { randString } from '@root/helpers/utils';

export interface Notification {
  id?: string;
  show?: boolean;
  message: string;
  variant: string;
}

type Notif = string | Notification;

interface IAlertContext {
  notifs?: Notification[];
  setNotif: (notif: Notif) => void;
}

export const AlertContext = createContext<IAlertContext>({
  notifs: [],
  setNotif: () => { },
});

const AlertContextProvider = ({ children }: { children: ReactNode }) => {
  const [notifs, setNotifsOnly] = useState<Notification[]>([]);

  const disableNotif = (id: string): Promise<void> => new Promise((resolve) => {
    setTimeout(() => {
      setNotifsOnly((items) => items.map((item) => (
        item.id === id && item.show ? { ...item, show: false } : item
      )));
    }, 3000);
    setTimeout(() => {
      setNotifsOnly((items) => items.filter((n) => n.id !== id));
      resolve();
    }, 6000);
  });

  const setNotif = (_notif: Notif) => {
    const id = randString();
    setNotifsOnly((items) => ([
      ...items,
      {
        id,
        message: typeof _notif === 'string' ? _notif : _notif.message,
        variant: typeof _notif === 'string' ? 'success' : _notif.variant,
        show: true,
      },
    ]));
    disableNotif(id);
  };

  const value = useMemo(() => ({
    notifs,
    setNotif,
  }), [notifs]);

  return (
    <AlertContext.Provider
      value={value}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContextProvider;
