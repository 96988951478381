import React, { useContext, useEffect, useState } from 'react';
import { ApprovalContext } from '@customer/contexts/approval.context';
import LabelValueCard from '@root/components/LabelValueCard/LabelValueCard';
import { ILabelValueCard } from '@root/interfaces/labelValueCard.interface';
import { setApprovalInformationsLabelValueDatas } from '@root/helpers/approval.helper';

const ApprovalInformations = () => {
  const { approvalToDisplay } = useContext(ApprovalContext);
  const [labelValue, setLabelValue] = useState<ILabelValueCard[]>([]);

  useEffect(() => {
    const labelValueDatas = setApprovalInformationsLabelValueDatas(approvalToDisplay);
    setLabelValue(labelValueDatas);
  }, [approvalToDisplay]);

  return (
    <div>
      <LabelValueCard labelValues={labelValue} />
    </div>
  );
};

export default ApprovalInformations;
