import {
  ApiResponse,
  safeFetch,
} from '@root/helpers/response-handler';
import { SessionUser } from '@root/interfaces/cognito.interface';
import {
  IYousignDocument,
  IYousignDocumentBody,
  ISignatureRequest,
  ISignatureRequestBody,
  ISignatureRequests,
  ISigner,
  ISignerBody,
  SignerInfo,
  SignerFields,
} from '@root/interfaces/yousign.interface';

const { REACT_APP_URL_DOCUMENT_API } = process.env;
const baseUrl = `${REACT_APP_URL_DOCUMENT_API}/v1/document/customer/yousign`;

// Signature request
export const getSignatureRequests = (): Promise<
ApiResponse<ISignatureRequest>
> => safeFetch(`${baseUrl}/signature_requests`);

export const getSignatureRequest = async (
  id: string,
): Promise<ApiResponse<ISignatureRequests>> => safeFetch(`${baseUrl}/signature_requests/${id}`);

export const createSignatureRequest = (
  body: ISignatureRequestBody,
): Promise<ApiResponse<ISignatureRequest>> => safeFetch(`${baseUrl}/signature_requests`, {
  method: 'POST',
  body: JSON.stringify(body),
});

export const updateSignatureRequest = (
  signatureRequestId: string,
  body: ISignatureRequestBody,
): Promise<ApiResponse<ISignatureRequest>> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}`,
  {
    method: 'PUT',
    body: JSON.stringify(body),
  },
);

export const deleteSignatureRequest = (
  signatureRequestId: string,
): Promise<ApiResponse> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}`,
  {
    method: 'DELETE',
  },
);

export const activateSignatureRequest = (
  signatureRequestId: string,
): Promise<ApiResponse<ISignatureRequest>> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/activate`,
  {
    method: 'POST',
  },
);

// documents
export const getDocuments = (
  signatureRequestId: string,
): Promise<ApiResponse<IYousignDocument[]>> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/documents`,
);

export const getDocument = (
  signatureRequestId: string,
  documentId: string,
): Promise<ApiResponse<IYousignDocument>> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/documents/${documentId}`,
);

export const addDocument = (
  signatureRequestId: string,
  body: any,
): Promise<ApiResponse<IYousignDocument>> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/documents`,
  {
    method: 'POST',
    body: JSON.stringify(body),
  },
);

export const updateDocument = (
  signatureRequestId: string,
  documentId: string,
  body: IYousignDocumentBody,
): Promise<ApiResponse> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/documents/${documentId}`,
  {
    method: 'PATCH',
    body: JSON.stringify(body),
  },
);

export const replaceDocument = (
  signatureRequestId: string,
  documentId: string,
  body: { fileId: string },
): Promise<ApiResponse> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/documents/${documentId}/replace`,
  {
    method: 'POST',
    body: JSON.stringify(body),
  },
);

export const deleteDocument = (
  signatureRequestId: string,
  documentId: string,
): Promise<ApiResponse> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/documents/${documentId}`,
  {
    method: 'DELETE',
  },
);

export const downloadDocument = (
  signatureRequestId: string,
): Promise<ApiResponse> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/documents/download`,
  {
    method: 'DELETE',
  },
);

// Signers
export const getSigners = (
  signatureRequestId: string,
): Promise<ApiResponse<ISigner[]>> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/signers`,
);

export const getSigner = async (
  signatureRequestId: string,
  signerId: string,
): Promise<ApiResponse<ISigner>> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/signers/${signerId}`,
);

export const addSigner = (
  signatureRequestId: string,
  body: ISignerBody,
): Promise<ApiResponse<ISigner>> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/signers`,
  {
    method: 'POST',
    body: JSON.stringify(body),
  },
);

export const addYousignSignerHelper = (
  yousignSignatureReqId: string,
  user: SessionUser,
  signatureFields: SignerFields[],
) => addSigner(yousignSignatureReqId, {
  info: {
    first_name: user.firstname!,
    last_name: user.lastname!,
    email: user.email!,
    phone_number: user.phoneNumber,
    locale: 'fr',
  },
  fields: signatureFields,
});

export const updateSigner = (
  signatureRequestId: string,
  signerId: string,
  body: SignerInfo,
): Promise<ApiResponse<ISignatureRequest>> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}/signers/${signerId}`,
  {
    method: 'PUT',
    body: JSON.stringify(body),
  },
);

export const deleteSigner = (
  signatureRequestId: string,
): Promise<ApiResponse> => safeFetch(
  `${baseUrl}/signature_requests/${signatureRequestId}`,
  {
    method: 'DELETE',
  },
);
