import React, { useContext, useEffect } from 'react';
import { ApprovalProviderContext } from '@provider/contexts/approvalProvider.context';
import { ApprovalSignature as IApprovalSignature, ApprovalStatusEnum } from '@root/interfaces/approval.interface';
import { useTranslation } from 'react-i18next';
import Yousign from '@root/helpers/yousign-iframe-sdk';
import { getApprovalSignatureByIdentifier, updateApproval, updateApprovalSignature } from '@root/services/approval.service';
import { UserContext } from '@root/contexts/user.context';
import { sendApprovalEmail } from '@root/services/approvalNotification.service';
import { ApprovalNotificationEventEnum } from '@root/interfaces/approvalNotification.interface';

const ApprovalSignature = () => {
  const { t } = useTranslation();
  const { approvalToDisplay } = useContext(ApprovalProviderContext);
  const { user } = useContext(UserContext);
  let yousign: any;

  const sendProviderHasSignedNotificationToCustomer = async () => {
    await sendApprovalEmail({
      event: ApprovalNotificationEventEnum.PROVIDER_HAS_SIGNED,
      emails: [approvalToDisplay.customer_email],
      provider_enterprise: user.currentEnterprise!.name,
      provider_name: `${user.firstname} ${user.lastname}`,
    });
  };

  const handleYouSignIframe = async () => {
    const res = await getApprovalSignatureByIdentifier(approvalToDisplay.id, user.id!);
    yousign = new Yousign({
      // end of URL query will be ignored in production
      signatureLink: `${res.data.signature_link}&disable_domain_validation=true`,
      iframeContainerId: 'yousign-iframe-container',
      isSandbox: !process.env.REACT_APP_ENV!.match(/prod(uction)?/),
    });
    // Download the doc after signature calls the function onSuccess again
    // so we need to check if user has already signed
    if (!res.data.has_signed) {
      // handle successful signature function
      yousign.onSuccess(async () => {
        // if user didn't already sign, update approval signature and approval entries in db
        const signatureNewValues: IApprovalSignature = {
          id: res.data.id,
          signed_at: new Date(),
          has_signed: true,
        };
        await updateApprovalSignature(signatureNewValues, approvalToDisplay.id, res.data.id!);

        await updateApproval(
          {
            id: approvalToDisplay.id,
            status: ApprovalStatusEnum.WAITING_FOR_CUSTOMER,
          },
          approvalToDisplay.id,
        );

        return sendProviderHasSignedNotificationToCustomer();
      });
    }
  };

  useEffect(() => {
    // initialize YouSign iFrame with signatureLink
    if (
      approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_PROVIDER
      || approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_CUSTOMER
      || approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_SPONSOR
      || approvalToDisplay.status === ApprovalStatusEnum.ACCEPTED
    ) {
      handleYouSignIframe();
    }
  }, [approvalToDisplay.status]);

  const displayContentDependingApprovalStatus = () => {
    // TODO: do other statuses
    switch (approvalToDisplay.status) {
      case ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION:
        return <p>{t('ApprovalsContainer.waitingSendToSignatureFromCustomer', 'Votre client est en cours de validation de la demande d’agrément. Vous serez bientôt invité à la signer.')}</p>;
      default:
        return (
          <p>{t('Approvals.waitingInformations', 'En attente d’informations complémentaires.')}</p>
        );
    }
  };

  return (
    <div>
      {approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_PROVIDER
      || approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_CUSTOMER
      || approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_SPONSOR
      || approvalToDisplay.status === ApprovalStatusEnum.ACCEPTED ? (
        <div id="yousign-iframe-container" />
        ) : (
          displayContentDependingApprovalStatus()
        )}
    </div>
  );
};

export default ApprovalSignature;
