import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovalContext } from '@customer/contexts/approval.context';
import { UserContext } from '@root/contexts/user.context';
import {
  ApprovalSignature as IApprovalSignature,
  ApprovalStatusEnum,
  ApprovalUserTypeEnum,
} from '@root/interfaces/approval.interface';
import Yousign from '@root/helpers/yousign-iframe-sdk';
import AWButton from '@root/components/AWButtons/AWButton';
import {
  activateSignatureRequest,
  addYousignSignerHelper,
  getDocuments,
} from '@root/services/yousign.service';
import {
  createApprovalSignature,
  getApprovalSignatureByIdentifier,
  getApprovalSignatures,
  updateApproval,
  updateApprovalSignature,
} from '@root/services/approval.service';
import { ApprovalNotificationEventEnum } from '@root/interfaces/approvalNotification.interface';
import { getEnterpriseMembers } from '@provider/services/enterprise.service';
import { sendApprovalEmail } from '@root/services/approvalNotification.service';
import { Spinner } from 'react-bootstrap';
import useLoadingPromise from '@root/hooks/useLoadingPromise';

const ApprovalSignature = () => {
  const { t } = useTranslation();
  const { approvalToDisplay } = useContext(ApprovalContext);
  const { user } = useContext(UserContext);
  const { waitWithLoad, isLoading } = useLoadingPromise();

  let yousign: any;

  const sendCustomerHasSignedNotificationToProvider = async () => {
    const userRes = await getEnterpriseMembers(approvalToDisplay.provider_id!, {
      fields: 'user_id,enterprise_id,firstname,lastname,name,email',
    });
    const emails = userRes.data?.filter((u) => !!u.email)?.map((u) => u.email) || [];

    await sendApprovalEmail({
      event: ApprovalNotificationEventEnum.CUSTOMER_HAS_SIGNED,
      emails,
      customer_enterprise: user.currentEnterprise!.name,
      customer_name: `${user.firstname} ${user.lastname}`,
      provider_enterprise: userRes.data[0].name,
    });
  };

  const handleYouSignIframe = async () => {
    const res = await getApprovalSignatureByIdentifier(approvalToDisplay.id, user.id!);
    yousign = new Yousign({
      // end of URL query will be ignored in production
      signatureLink: `${res.data.signature_link}&disable_domain_validation=true`,
      iframeContainerId: 'yousign-iframe-container',
      isSandbox: !process.env.REACT_APP_ENV!.match(/prod(uction)?/),
    });
    // Download the doc after signature calls the function onSuccess again
    // so we need to check if user has already signed
    if (!res.data.has_signed) {
      // handle successful signature function
      yousign.onSuccess(async () => {
        // if user didn't already sign, update approval signature and approval entries in db
        const signatureNewValues: IApprovalSignature = {
          id: res.data.id,
          signed_at: new Date(),
          has_signed: true,
        };
        await updateApprovalSignature(signatureNewValues, approvalToDisplay.id, res.data.id!);

        await updateApproval(
          {
            id: approvalToDisplay.id,
            status: ApprovalStatusEnum.WAITING_FOR_SPONSOR,
          },
          approvalToDisplay.id,
        );

        return sendCustomerHasSignedNotificationToProvider();
      });
    }
  };

  useEffect(() => {
    // initialize YouSign iFrame with signatureLink
    if (
      approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_CUSTOMER
      || approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_SPONSOR
      || approvalToDisplay.status === ApprovalStatusEnum.ACCEPTED
    ) {
      handleYouSignIframe();
    }
  }, [approvalToDisplay.status]);

  const addCustomerAsYousignSigner = async () => {
    const yousignDocs = await getDocuments(approvalToDisplay.yousign_signature_request_id!);

    const addSignerRes = await addYousignSignerHelper(
      approvalToDisplay.yousign_signature_request_id!,
      user,
      [
        {
          // TODO: get those values from db
          // customer values only suitable for DC4 template
          document_id: yousignDocs.data[0].id,
          type: 'signature',
          height: 100,
          width: 158,
          page: 5,
          x: 165,
          y: 134,
        },
      ],
    );

    // adding that signer also in database
    const newApprovalSignature: IApprovalSignature = {
      approval_id: approvalToDisplay.id,
      enterprise_id: user.currentEnterprise!.id,
      user_id: user.id,
      signer_id: addSignerRes.data.id,
      user_type: ApprovalUserTypeEnum.CUSTOMER,
    };
    return createApprovalSignature(newApprovalSignature, approvalToDisplay.id);
  };

  const sendSignatureProcessInitiatedNotificationToProvider = async () => {
    const userRes = await getEnterpriseMembers(approvalToDisplay.provider_id!, {
      fields: 'user_id,enterprise_id,firstname,lastname,name,email',
    });
    const emails = userRes.data?.filter((u) => !!u.email)?.map((u) => u.email) || [];

    await sendApprovalEmail({
      event: ApprovalNotificationEventEnum.SIGNATURE_PROCESS_INITIATED,
      emails,
      customer_enterprise: user.currentEnterprise!.name,
      customer_name: `${user.firstname} ${user.lastname}`,
      provider_enterprise: userRes.data[0].name,
    });
  };

  const activateSignRequestToGetSignatureLinks = async (signRequestId: string) => {
    // getting signers from response of activating the signature request
    const activateSignRes = await activateSignatureRequest(signRequestId);
    const signersFromActivationSignature = activateSignRes.data.signers;

    // getting signers from database
    const approvalSignaturesRes = await getApprovalSignatures(approvalToDisplay.id);
    const signersFromDb = approvalSignaturesRes.data;

    // we compare signers got from activation response with signers database
    // the goal is to match ids of a same signer to get the signature_link and insert it into db
    const matchedSigners = signersFromActivationSignature
      .filter((signerFromActivation) => signersFromDb
        .some((dbSigner) => dbSigner.signer_id === signerFromActivation.id))
      .map((signer) => {
        const dbSignature = signersFromDb.find((dbSigner) => dbSigner.signer_id === signer.id);
        if (dbSignature) {
          return { signer_id: dbSignature.signer_id, signature_link: signer.signature_link };
        }
        return {};
      });

    // for each signer found, we insert the signature_link in db
    matchedSigners.forEach(async (signer) => {
      const signatureRes = await getApprovalSignatureByIdentifier(
        approvalToDisplay.id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        signer?.signer_id!,
      );

      const signatureNewValues: IApprovalSignature = {
        id: signatureRes.data.id,
        signature_link: signer?.signature_link,
      };

      return updateApprovalSignature(
        signatureNewValues,
        approvalToDisplay.id,
        signatureRes.data.id!,
      );
    });
  };

  const putIntoSignature = async () => {
    await addCustomerAsYousignSigner();
    await activateSignRequestToGetSignatureLinks(approvalToDisplay.yousign_signature_request_id!);
    await updateApproval(
      {
        id: approvalToDisplay.id,
        status: ApprovalStatusEnum.WAITING_FOR_PROVIDER,
      },
      approvalToDisplay.id,
    );
    return sendSignatureProcessInitiatedNotificationToProvider();
  };

  const displayContentDependingApprovalStatus = () => {
    // TODO: do other statuses
    switch (approvalToDisplay.status) {
      case ApprovalStatusEnum.WAITING_CUSTOMER_VALIDATION:
        return (
          <>
            <p className="mb-4">
              {t(
                'ApprovalsContainer.sendToSignatureText',
                'Votre sous-traitant a saisi ses variables. Nous vous invitons à vérifier toutes les informations et à démarrer la mise en signature en cliquant sur le bouton ci-dessous.',
              )}
            </p>
            <AWButton onClick={() => waitWithLoad(putIntoSignature())} disabled={isLoading}>
              {isLoading ? (
                <Spinner size="sm" animation="border" />
              ) : (
                t('ApprovalsContainer.sendToSignature', 'Mise en signature')
              )}
            </AWButton>
          </>
        );
      default:
        return (
          <p>{t('Approvals.waitingInformations', 'En attente d’informations complémentaires.')}</p>
        );
    }
  };

  return (
    <div>
      {approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_CUSTOMER
      || approvalToDisplay.status === ApprovalStatusEnum.WAITING_FOR_SPONSOR
      || approvalToDisplay.status === ApprovalStatusEnum.ACCEPTED ? (
        <div id="yousign-iframe-container" />
        ) : (
          displayContentDependingApprovalStatus()
        )}
    </div>
  );
};

export default ApprovalSignature;
